import React from 'react'
import style from '../../../styles/mystyle.module.css'
import { useTranslation } from 'next-i18next'
import * as config from '../../../next.config.js'
import Image from 'next/image'
import {useRouter} from 'next/router'
const DownloadApp = () => {
  const { t } = useTranslation("common")
  const router=useRouter()
  return (
    <div className={style.BgDownload}>
    <div className={style.ContainerDownload}>
      <div className={style.allcard}>
        <div className={style.downloadsection}>

          {/* <p className={style.hakeemapp} >{t("DownloadAppNow")}</p> */}
          <div className={style.DownloadHeader}>
            <p className={style.hakeemapp} >{t("DownloadAppNow")}</p>
            <div className={style.Download}>



              <div className={style.card}>

                <a target={"_blank"}
                  href="http://play.google.com/store/apps/details?id=com.yottagate.hakeem">
                  <button className={style.btnhakeemapp} >
           
                      <Image src={`${config.basePath}/assest/Rectangle 859.svg`}
                        width={40}
                        height={40}
                        alt={"hakeem-app"}
                        className={style.downloadImg1}
                      ></Image>
                  
                    <p className={style.appStore} >{t("PlayStore")}</p>
                  </button>
                </a>
              </div>
              <div className={style.card}>


                <a target={"_blank"}
                  href="https://apps.apple.com/us/app/hakeem/id1569134476"><button className={style.btnhakeemapp} >
                    <div className={style.downloadImg1}>
                      <Image
                        className={style.downloadImg1}
                        src={`${config.basePath}/assest/Rectangle 860.svg`}
                        width={40}
                        height={40}
                        alt={"hakeem-app"}
                      ></Image>
                    </div>
                    <p className={style.appStore} >{t("AppStore")}</p>

                  </button></a>
              </div></div></div>
          <div className={style.card}>
            <div className={style.downloadImg}>
              {router.locale=="ar"?<Image
                className={style.downloadImg}
                src={`${config.basePath}/images/phones/1.png`}
                alt={"hakeem-app"}
                width={400}
                height={400}
                quality={100}
              ></Image>:<Image
              className={style.downloadImg}
              src={`${config.basePath}/images/phones/2.png`}
              alt={"hakeem-app"}
              width={400}
              height={400}
              quality={100}
            ></Image>}
            </div>
          </div>

        </div>
      </div>
    </div>
    </div>
  )
}
export default DownloadApp