import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "next-i18next";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import style from '../../styles/mystyle.module.css'
import TextField from "@mui/material/TextField";
import { styled } from '@mui/system'
import { constants } from "../../constants";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { FormHelperText } from "@mui/material";
import { useRouter } from "next/router";
import { getCookieValue, buidlObject } from "../helpers/helpers";
import { useMedia } from "../../utils/use-media";
import LoadingButton from "@mui/lab/LoadingButton"
import TermsContext from "../../context/Trems"
// import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {getTermsByVocabCodes} from "../helpers/global/term/term";
import {useQuery} from '@tanstack/react-query'
const Customebtn = styled(LoadingButton)({
  padding: " 5px 10px !important",
  color: "white",
  backgroundColor: "#F52A59",
  border: "none",
  borderRadius: "10px",
  width: "40%",
  height: "auto",
  fontSize: "1.1rem",
  zindex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "-3%",

  '&.Mui-disabled': {
    color: "grey !important",
    border: "none !important",
    backgroundColor: "#bcbcbc70 !important",

  },
  '&:hover': {
    border: "none"
  },
  '@media (min-width:100px) and (max-width:768px)': {
    padding: " 5px 10px !important",
    color: "white !important ",
    backgroundColor: " #F52A59 !important",
    fontSize: "1.3rem !important",
    width: "40% !important  ",
    border: "none !important",
    borderRadius: '10px !important',
    height: "auto",
    display: "flex !important",
    zindex: 1,

    alignItems: "center !important",
    justifyContent: "center !important",
    marginLeft: "auto !important",
    marginRight: "auto !important",
    marginTop: "-4% !important",



  },

  '@media (min-width:768px) and (max-width:912px)': {


    padding: " 5px 10px !important",
    color: "white !important ",
    backgroundColor: " #F52A59 !important",
    fontSize: "1.1rem !important",
    width: "20% !important  ",
    border: "none !important",
    borderRadius: '10px !important',
    height: "auto",
    display: "flex !important",
    zindex: 1,

    alignItems: "center !important",
    justifyContent: "center !important",
    marginLeft: "auto !important",
    marginRight: "auto !important",
    marginTop: "-3% !important",


  },
  '@media (min-width:913px) ': {

    padding: " 5px 10px !important",
    color: "white !important ",
    backgroundColor: " #F52A59 !important",
    fontSize: "1.3rem !important",
    width: "20% !important  ",
    border: "none !important",
    borderRadius: '10px !important',
    height: "auto",
    display: "flex !important",
    zindex: "1 !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    marginLeft: "auto !important",
    marginRight: "auto !important",
    marginTop: "-1% !important",



  },

});
// const CustomeCheckbox=styled(Checkbox)({
//   '&.Mui-checked':{
//     color:"#008C8D  !important",
   
  
//    },
    
//         padding:"0px ",
       
//         color:"#008C8D  !important",
       
//        '@media (min-width:768px) and (max-width:912px)':{
//           padding: "0px"
//       }
//  })
// const CustomeTextField = styled(TextField)({
//   "& .MuiInputLabel-root": {
//     fontSize: "1rem !important"
//   },
//   "@media (min-width:768px) and (max-width:912px)": {
//     "& .MuiInputLabel-root": {
//       fontSize: "1rem !important"
//     }
//   },
//   "@media (min-width:913px) ": {
//     "& .MuiInputLabel-root": {
//       fontSize: "1rem !important"
//     }
//   }
// });
const CustomeFormControl = styled(FormControl)(({ type }) => ({
  width: "100%",
  margin: 0,
  "& label.Mui-focused": {
    color: "#008C8D "
  },

  "& .MuiSelect-icon": {
    color: "#008C8D ",
    display: type ? "none" : ""
  },
  "& .MuiInputLabel-root": {
    fontSize: "1rem !important"
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    boxShadow: "0 0 4px #bcbcbc !important",
    border:"unset"
},
  "@media only screen and  (min-width:280px){": {
    "& .MuiInputLabel-root": {
      fontSize: "1rem !important"
    }
  },
  "@media (min-width:768px) and (max-width:912px)": {
    "& .MuiInputLabel-root": {
      fontSize: "1rem !important"
    },
    width: "100%"
  },
  "@media (min-width:913px) ": {
    "& .MuiInputLabel-root": {
      fontSize: "1rem !important"
    },
    width: "100%"
  }
}));
const Customeselect = styled(Select)(({ locale }) => ({
  "& .MuiSelect-select": {
    textAlign: locale == "ar" ? "left" : "left"
  }
}));
// const CustomeBorder = styled('div')(({ locale }) => ({
//   borderRight: "none",
//   "@media (min-width:768px) and (max-width:912px)": {

//     borderLeft: locale == "ar" ? "1px solid #e0e0e0" : "1px solid #e0e0e0"
//   },
//   "@media (min-width:913px) ": {
//     borderLeft: locale == "ar" ? "1px solid #e0e0e0" : "1px solid #e0e0e0"
//   }
// }));
function FormSearch({ onClose }) {
  const { t } = useTranslation("common");
  const vocabTerms = useContext(TermsContext)
  const router = useRouter();
  const mobile = useMedia("(max-width: 580px)");
  const [entityTypes, setEntityTypes] = useState([{}]);
  const [cities, setcities] = useState([]);
  const [cityName, setCityName] = useState("");
  const [districts, setDistricts] = useState([]);
  const [majorsTypes, setMajorTypes] = useState([]);
  const [subMajors, setSubMajors] = useState([]);
  const [MajorName, setMajorName] = useState("");
  const [subMajorName, setSubMajorName] = useState("");
  const [districtName, setDistrictname] = useState("");
  const [officialName, setOfficialName] = useState("");
  const [inputList, setInputList] = useState([
    {
      city: null,
      district: null,
      major: null,
      subMajor: null,
      entityType: null,
      officialName: null
    }
  ]);
  const [EntityTypeName, setEntityTypeName] = useState([]);
  // const [checkedType,setCheckedType]=useState([])
  const [loading, setLloading] = useState(false);
  const [chechkOfList, setchechkOfList] = useState(false);
  function FormHelperTxet() {
    const { focused } = useFormControl() || {};
    const helperText = React.useMemo(() => {
      if (focused && cityName == "") {
        return <>{t("helperTextDistricts")}</>;
      }

    }, [focused]);
    return <FormHelperText> {helperText}</FormHelperText>;
  }
  const handleClearCity = () => {
    setCityName("");
    setDistrictname("");
    const list = [...inputList];
    list[0]["city"] = null;
    setInputList(list);
    setDistricts([]);
  };

  const handleClearDistrict = () => {
    setDistrictname("");
    const list = [...inputList];
    list[0]["district"] = null;
    setInputList(list);
  };

  const handleClearMajor = () => {
    setMajorName("");
    setSubMajorName("");
    const list = [...inputList];
    list[0]["major"] = null;
    setInputList(list);
  };
  const handleClearSubMajor = () => {
    setSubMajorName("");
    const list = [...inputList];
    list[0]["subMajor"] = null;
    setInputList(list);
  };
  const handleClearEntityType = () => {
    setEntityTypeName([]);

    const list = [...inputList];
    list[0]["entityType"] = null;
    setInputList(list);
  };
  const handleChangeCities = event => {
    let StatusIds = vocabTerms.statusIds;
    // if(typeof StatusIds=='string'){
    //     StatusIds=JSON.parse(StatusIds)
    // }    
    if (event.target.value) {
      setchechkOfList(false)
      const list = [...inputList];
      list[0]["city"] = cities.filter(i => i.name == event.target.value);
      setInputList(list);
      let codecity;
      setCityName(event.target.value);
      codecity = cities.filter(i => i.name == event.target.value)[0].code;
      let response = districtsLst;
      if (response.length > 0) {
        response = response.filter(
          x =>
            x.language.code == router.locale && x.vocCode == "districts" && x.field1 == codecity && StatusIds.includes(x.statusId)
        );
        setDistricts(response);
      }

    } else {
      setDistricts([]);
    }
  };
  const [showAddressSection,setShowAddressSection]=useState(false)
  const [showMajorsSection,setShowMajorSection]=useState(false)
  const [isMedical,setIsMedical]=useState(false)
  const [isFood,setIsFood]=useState(false)
  const [allCities,setAllCiteies]=useState([])
  const handleChangeEntityTypes = event => {
    if (event.target.value) {
      setchechkOfList(false)
      const list = [...inputList];
      setcities(allCities)
 let tempEntityType=entityTypes.filter(
  i => event.target.value==i.name)[0]
  // console.log("tempEntityType===>",tempEntityType)
  if(tempEntityType.fieldConfig==""){
    setShowAddressSection(true)
    setShowMajorSection(false)
    setIsFood(false)
    setIsMedical(false)
  }
  if(tempEntityType.fieldConfig&&buidlObject(tempEntityType.fieldConfig).category=="E-commerce"){
    setShowAddressSection(true)
    setShowMajorSection(false)
    setIsFood(false)
    setIsMedical(false)
  }
  if(tempEntityType.fieldConfig&&buidlObject(tempEntityType.fieldConfig).category=="food"){
    setShowMajorSection(false)
    setShowAddressSection(true)
    setIsFood(true)
    setIsMedical(false)
    setcities(cities.filter(ele=>ele.fieldConfig&&buidlObject(ele.fieldConfig).search&&buidlObject(ele.fieldConfig).search.includes("food")))
  }
  if(tempEntityType.fieldConfig&&buidlObject(tempEntityType.fieldConfig).category=="medical"){
    setShowMajorSection(true)
    setShowAddressSection(true)
    setIsFood(false)
    setIsMedical(true)
  }

      list[0]["entityType"] = entityTypes.filter(
        i => event.target.value==i.name)
      setInputList(list);
      setEntityTypeName(event.target.value);

    }
  
  };

  const handleChangeOfficialName = event => {

    const list = [...inputList];
    if (event.target.value == "" || event.target.value == null) {
      list[0]["officialName"] = null;
      setInputList(list);
    } else {
      list[0]["officialName"] = event.target.value;
      setInputList(list);
    }
    setchechkOfList(false)
    setOfficialName(event.target.value);
  };
  const handleChangedistricts = event => {
    if (event.target.value) {
      setchechkOfList(false)
      const list = [...inputList];
      list[0]["district"] = districts.filter(i => i.name == event.target.value);
      setInputList(list);
      setDistrictname(event.target.value);
    }
  };

  const handleMajors = event => {
    if (event.target.value) {
      setchechkOfList(false)
      const list = [...inputList];
      list[0]["major"] = majorsTypes.filter(i => i.name == event.target.value);
      setInputList(list);
      setMajorName(event.target.value);
      let codesubMajor = majorsTypes.filter(
        i => i.name == event.target.value
      )[0].code;
      let response = subMajorsLst.filter(ele => ele.field1 == codesubMajor && ele.language.code == router.locale);
      setSubMajors(response);
    }
  };
  const handleSubMajors = event => {
    if (event.target.value) {
      setchechkOfList(false)
      const list = [...inputList];
      list[0]["subMajor"] = subMajors.filter(i => i.name == event.target.value);
      setInputList(list);
      setSubMajorName(event.target.value);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    if ((inputList[0].entityType == null && inputList[0].major == null && inputList[0].city == null && inputList[0].district == null && inputList[0].subMajor == null && inputList[0].officialName == null)) {
      setchechkOfList(true)
    } else {
      let entityTypecode = ''
      let data = {};
      setLloading(true);
      if (inputList[0].entityType && inputList[0].entityType.length > 0) {
        let x = entityTypes.filter(i => inputList[0].entityType.map(ele => ele.code).includes(i.code));

        if (x.length > 0) {
          x.map(n => {
            entityTypecode += n.code + ","
          }
          )


        }
        data.entityType = entityTypecode
      }

      if (inputList[0].major && inputList[0].major.length > 0) {
        let x = majorsTypes.filter(i => i.code == inputList[0].major[0].code);
        if (x.length > 0) {
          data.major = x[0].name;
        }
      }

      if (inputList[0].city && inputList[0].city.length > 0) {
        let x = cities.filter(i => i.code == inputList[0].city[0].code);
        if (x.length > 0) {
          data.city = x[0].name;
        }
      }

      if (inputList[0].officialName) {
        data.entityName = inputList[0].officialName;
      }

      if (inputList[0].district && inputList[0].district.length > 0) {
        let x = districts.filter(i => i.code == inputList[0].district[0].code);
        if (x.length > 0) {
          data.district = x[0].name;
        }
      }

      if (inputList[0].subMajor && inputList[0].subMajor.length > 0) {
        let x = subMajors.filter(i => i.code == inputList[0].subMajor[0].code);
        if (x.length > 0) {
          data.subMajor = x[0].name;
        }
      }
      if(isMedical==true){
        data.isMedical=true
      }
      if(isFood==true){
        data.isFood=true
      }
      
      router
        .push({
          pathname: "/" + constants.entityLink,
          query: data
        })
        .then(e => {
          setLloading(false);
          if (onClose) {
            onClose(false)
          }
        });
    }
  };
  const [subMajorsLst,setSubMajorsLst]=useState([])
  const [districtsLst,setDistrictsLst]=useState([])
  // const [entityCategories,setEntityCategories]=useState([])
  const info=useQuery({queryKey:["entity-categories","sub-majors-types","districts","cities","majors-types"],queryFn:async()=>await getTermsByVocabCodes(["entity-categories","sub-majors-types","districts","cities","majors-types"])})

  useEffect( () => {
    let StatusIds = vocabTerms.statusIds;
    
    // getTermsByVocabCodes(["entity-categories","sub-majors-types","districts"]).then(termRes=>{

  
    let termLst=info.data&&JSON.parse(info.data)||[]
    // console.log("termLst===========>",termLst.filter(ele=>ele.vocCode=="districts"))
    // termLst=termLst
    let tempEntityCategories=termLst.filter(ele=>ele.vocCode=="entity-categories").filter(x=> x.field3!='' && x.field3!=null&&JSON.parse(x.field3)&&(JSON.parse(x.field3).displayInSearch=='true')).filter(ele=> StatusIds.includes(ele.statusId) &&
    ele.language.code == router.locale)
    setSubMajorsLst(termLst.filter(ele=>ele.vocCode=="sub-majors-types"))
    setDistrictsLst(termLst.filter(ele=>ele.vocCode=="districts"))
    let EntityTypes2;
  
    if (vocabTerms.entityTypes.length > 0) {
      let tempLst=[]
      let tempLstOfCode=[]
      vocabTerms.entityTypes.filter(
        x =>
          x.field3 == 1 &&
          StatusIds.includes(x.statusId) &&
          x.language.code == router.locale
      ).concat(tempEntityCategories) .map(ele=>{
        let indexOfEn=tempLstOfCode.indexOf(ele.code)
        if(indexOfEn==-1){
          tempLst.push(ele)
          tempLstOfCode.push(ele.code)
        }
      })
      setEntityTypes(prev=>[...tempLst]
      );
      EntityTypes2 = tempLst
    }

    if (router.query.entityType) {
      let n = EntityTypes2.filter(i => router.query.entityType.includes(i.code))
        .length > 0
        ? EntityTypes2.filter(i => router.query.entityType.includes(i.code)).map(e => e.name)
        : []
      setEntityTypeName(n)
      const list = [...inputList];
      list[0]["entityType"] = EntityTypes2.filter(
        i => router.query.entityType.includes(i.code)
      );
      setInputList(list);
    }
    let cities2=[]
    // console.log("cities===========>",termLst.filter(ele=>ele.vocCode=="cities"))
    if (  termLst.filter(ele=>ele.vocCode=="cities").length > 0) {
      setcities(
        termLst.filter(ele=>ele.vocCode=="cities").filter(
          x =>
          StatusIds.includes(x.statusId) &&
            x.field1 == getCookieValue("userCountry") &&
            x.language.code == router.locale
        )
      
      );
      setAllCiteies( termLst.filter(ele=>ele.vocCode=="cities").filter(
        x =>
        StatusIds.includes(x.statusId) &&
          x.field1 == getCookieValue("userCountry") &&
          x.language.code == router.locale
      ))
      cities2 =   termLst.filter(ele=>ele.vocCode=="cities").filter(
        x =>
        StatusIds.includes(x.statusId) &&
          x.language.code == router.locale
      );
    }

    if (router.query.city) {
      setCityName(
        cities2.filter(i => i.name == router.query.city).length > 0
          ? cities2.filter(i => i.name == router.query.city)[0].name
          : ""
      );
      const list = [...inputList];
      list[0]["city"] = cities2.filter(i => i.name == router.query.city);
      setInputList(list);

      if (list[0].city.length > 0) {
        let codecity = list[0].city[0].code;

        let response =   termLst.filter(ele=>ele.vocCode=="districts").filter(ele => ele.field1 == codecity && ele.language.code == router.locale);

        if (response.length > 0) {
          response = response.filter(
            x =>
            StatusIds.includes(x.statusId) &&

              x.language.code == router.locale
          );
          setDistricts(response);
        }

        if (router.query.district) {
          setDistrictname(
            response.filter(i => i.name == router.query.district)
              .length > 0
              ? response.filter(
                i => i.name == router.query.district
              )[0].name
              : ""
          );
          const list = [...inputList];
          inputList[0].district = response.filter(
            i => i.name == router.query.district
          );

          setInputList(list);
        }


      }
    }



    let MajorTypes2;
    if (  termLst.filter(ele=>ele.vocCode=="majors-types").length > 0) {
      setMajorTypes(
        termLst.filter(ele=>ele.vocCode=="majors-types").filter(
          x =>

            StatusIds.includes(x.statusId) &&
            x.language.code == router.locale
        )
      );
      MajorTypes2 =   termLst.filter(ele=>ele.vocCode=="majors-types").filter(
        x =>

          StatusIds.includes(x.statusId) &&
          x.language.code == router.locale
      );
    }

    if (router.query.major) {
      setMajorName(
        MajorTypes2.filter(i => i.name == router.query.major).length > 0
          ? MajorTypes2.filter(i => i.name == router.query.major)[0].name
          : ""
      );
      const list = [...inputList];
      list[0]["major"] = MajorTypes2.filter(
        i => i.name == router.query.major
      );
      setInputList(list);

      if (list[0].major.length > 0) {
        let codesubMajor = list[0].major[0].code;

        let response =   termLst.filter(ele=>ele.vocCode=="sub-majors-types").filter(ele => ele.field1 == codesubMajor && ele.language.code == router.locale);

        if (response.length > 0) {
          response = response.filter(
            x =>
            StatusIds.includes(x.statusId) &&
              x.language.code == router.locale
          );
        }

        setSubMajors(response);
        if (router.query.subMajor) {
          setSubMajorName(
            response.filter(i => i.name == router.query.subMajor)
              .length > 0
              ? response.filter(
                i => i.name == router.query.subMajor
              )[0].name
              : ""
          );
          const list = [...inputList];
          list[0]["subMajor"] = response.filter(
            i => i.name == router.query.subMajor
          );
          setInputList(list);
        }

      }
    }

  // }).catch(err=>err)
    if (router.query.entityName) {
      setOfficialName(router.query.entityName);
      const list = [...inputList];
      list[0]["officialName"] = router.query.entityName;
      setInputList(list);
    }
  }, [router.query,info.data]);
  return (
    <div className={style.SectionContainer}>
      {/* {console.log("info========>",JSON.parse(info.data))} */}
      <form className={style.formSearch}>
        <div className={style.formUp} style={{gridTemplateColumns:"100%"}}>
          {/* <div >
            <CustomeTextField
              id
              label={t("searchByName")}
              value={officialName}
              onChange={handleChangeOfficialName}
              sx={{
                width: "100%",
                margin: "0 ",
                display: "flex !important",
                borderRadius: 0
              }}

            />
          </div> */}
          {/* <div> */}

            {/* <CustomeBorder locale={router.locale}> */}
        
        </div>
        {/* {mobile ? "" : <hr style={{ width: "95%", margin: "auto", height: "2px" }} />} */}
        <div className={style.formdown} style={{gridTemplateColumns:"100%"}}>
        <div > 
              <CustomeFormControl fullWidth type={EntityTypeName.length>0}>
                <InputLabel id="Type">{t("Type")}</InputLabel>
                <Customeselect
                  locale={router.locale}
                  fullWidth
                  labelId="Type"
                  id="Type"
                  // multiple
                  value={EntityTypeName}
                  onChange={ handleChangeEntityTypes}
                  sx={{
                    // direction: router.locale == "ar" ? "ltr" : "rtl",
                    width: "100%",
                    margin: "0 ",
                    display: "flex !important",
                    borderRadius: 0
                  }}
                  // renderValue={(selected) => selected.filter(i=>i!=t("all")).join(', ')}
               
               
                  endAdornment={
                    EntityTypeName&&EntityTypeName.length>0 ? <IconButton
                      sx={{
                        visibility: EntityTypeName ? "visible" : "hidden",
                        position: "relative",
                        // right: router.locale == "ar" ? "5% ":"5%",


                      }}
                      onClick={handleClearEntityType}
                    >
                      <ClearIcon />
                    </IconButton> : ""
                  }
                >
            
                  {entityTypes.length > 0
                    ? entityTypes.map((type, index) => (
                      <MenuItem
                        style={{
                          direction: router.locale == "ar" ? "rtl" : "ltr",
                          alignItems: "right"
                        }}
                        value={type.name}
                        key={index}
                      >
                        {/* <FormControlLabel
                          control={ <CustomeCheckbox id={type.code}  checked={EntityTypeName.includes(type.name)} />
                          }
                          label={type.name}
                        /> */}
                        
                        {type.name}
                      </MenuItem>
                    ))
                    : ""}
                </Customeselect>
              </CustomeFormControl>
            {/* </CustomeBorder> */}
          </div>
          {showAddressSection?<>
          <div >
            <CustomeFormControl fullWidth type={cityName}>
              <InputLabel id="City">{t("City")}</InputLabel>
              <Customeselect
                locale={router.locale}
                fullWidth
                labelId="City"
                id="City"
                label={t("City")}
                onChange={handleChangeCities}
                value={cityName}
                sx={{
                  // direction: "rtl",
                  // textAlign: " right",
                  width: "100%",
                  margin: "0 ",
                  display: "flex !important",
                  borderRadius: 0
                }}
                endAdornment={
                  cityName ? <IconButton
                    sx={{
                      visibility: cityName ? "visible" : "hidden",

                      position: "relative",
                      // right: router.locale == "ar" ? "5% " : "5%",
                    }}
                    onClick={handleClearCity}
                  >
                    <ClearIcon />
                  </IconButton> : ""
                }
              >
                {cities.length > 0
                  ? cities.map((city, index) => (
                    <MenuItem
                      style={{
                        direction: router.locale == "ar" ? "rtl" : "ltr",
                        alignItems: "right"
                      }}
                      value={city.name}
                      key={index}
                    >
                      {city.name}
                    </MenuItem>
                  ))
                  : ""}
              </Customeselect>
            </CustomeFormControl>
          </div>
          <div >
            {/* <CustomeBorder locale={router.locale}> */}
              <CustomeFormControl fullWidth type={districtName}>
                <InputLabel id="Zone">{t("Zone")}</InputLabel>
                <Customeselect
                  locale={router.locale}
                  fullWidth
                  labelId="Zone"
                  id="Zone"
                  label={t("Zone")}
                  onChange={handleChangedistricts}
                  value={districtName}
                  sx={{
                    // direction: router.locale == "ar" ? "ltr" : "ltr",
                    width: "100%",
                    margin: "0 ",
                    display: "flex !important",
                    borderRadius: 0
                  }}
                  endAdornment={
                    districtName ? <IconButton
                      sx={{
                        visibility: districtName ? "visible" : "hidden",
                        position: "relative",
                        // right: router.locale == "ar" ? "5% " : "5%",
                      }}
                      onClick={handleClearDistrict}
                    >
                      <ClearIcon />
                    </IconButton> : ""
                  }
                >
                  {districts.length > 0
                    ? districts.map(district => (
                      <MenuItem
                        style={{
                          direction: router.locale == "ar" ? "rtl" : "ltr",
                          alignItems: "right"
                        }}
                        value={district.name}
                        key={district.id}
                      >
                        {district.name}
                      </MenuItem>
                    ))
                    : ""}
                </Customeselect>
                <FormHelperTxet />
              </CustomeFormControl>
            {/* </CustomeBorder> */}
          </div>
          </>:""}
          {showMajorsSection?<>
          <div >
            {/* <CustomeBorder locale={router.locale}> */}
              <CustomeFormControl fullWidth type={MajorName}>
                <InputLabel id="Specialization">
                  {t("Specialization")}
                </InputLabel>
                <Customeselect
                  locale={router.locale}
                  fullWidth
                  labelId="Specialization"
                  id="Specialization"
                  label={t("Specialization")}
                  onChange={handleMajors}
                  value={MajorName}
                  sx={{
                    // direction: router.locale == "ar" ? "rtl" : "ltr",
                    width: "100%",
                    margin: "0 ",
                    display: "flex !important",
                    borderRadius: 0
                  }}
                  endAdornment={
                    MajorName ? <IconButton
                      sx={{
                        visibility: MajorName ? "visible" : "hidden",
                        position: "relative",
                        // right: router.locale == "ar" ? "5% " : "5%",
                      }}
                      onClick={handleClearMajor}
                    >
                      <ClearIcon />
                    </IconButton> : ""
                  }
                >
                  {majorsTypes.length > 0
                    ? majorsTypes.map(major => (
                      <MenuItem
                        style={{
                          direction: router.locale == "ar" ? "rtl" : "ltr",
                          alignItems: "right"
                        }}
                        value={major.name}
                        key={major.id}
                      >
                        {major.name}
                      </MenuItem>
                    ))
                    : ""}
                </Customeselect>
              </CustomeFormControl>
            {/* </CustomeBorder> */}
          </div>
          <div >
            {/* <CustomeBorder locale={router.locale}> */}
              <CustomeFormControl fullWidth type={subMajorName}>
                <InputLabel id="SubSpecialization">
                  {t("SubSpecialization")}
                </InputLabel>
                <Customeselect
                  locale={router.locale}
                  fullWidth
                  labelId="SubSpecialization"
                  id="SubSpecialization"
                  label={t("SubSpecialization")}
                  onChange={handleSubMajors}
                  value={subMajorName}
                  sx={{
                    // direction: router.locale == "ar" ? "rtl" : "ltr",
                    width: "100%",
                    margin: "0 ",
                    display: "flex !important",
                    borderRadius: 0
                  }}
                  endAdornment={
                    subMajorName ? <IconButton
                      sx={{
                        visibility: subMajorName ? "visible" : "hidden",
                        position: "relative",
                        // right: router.locale == "ar" ? "5% " : "5%",
                      }}
                      onClick={handleClearSubMajor}
                    >
                      <ClearIcon />
                    </IconButton> : ""
                  }
                >
                  {subMajors.length > 0
                    ? subMajors.map((subMajor, index) => (
                      <MenuItem
                        style={{
                          direction: router.locale == "ar" ? "rtl" : "ltr",
                          alignItems: "right"
                        }}
                        value={subMajor.name}
                        key={index}
                      >
                        {subMajor.name}
                      </MenuItem>
                    ))
                    : ""}
                </Customeselect>
              </CustomeFormControl>
            {/* </CustomeBorder> */}
          </div>
          </>:""}

        </div>
      </form>
      <Customebtn className={style.searchbtn} loading={loading} onClick={e => handleSubmit(e)} loadingPosition="start">
        {t("Search")}
      </Customebtn>
      {chechkOfList == true ?
        <>
          <FormHelperText
            error={chechkOfList == true ? true : false}
            style={{ textAlign: "center" }}
          >
            {t("validateSearch")}
          </FormHelperText>
        </>
        : ""}
    </div>
  );
}

export default FormSearch;
