import React, { useContext } from 'react'
import style from '../../../styles/mystyle.module.css'
// import Container from '@mui/material/Container'
// import { Link } from '@mui/material'
import Link from 'next/link'
// import { useRouter } from 'next/router'
import * as config from '../../../next.config.js'
import { useTranslation } from 'next-i18next'
// import Twitter from '@mui/icons-material/Twitter'
import Telegram from '@mui/icons-material/Telegram'
import LinkedIn from '@mui/icons-material/LinkedIn'
import Facebook from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import { useMedia } from "../../../utils/use-media";
import { constants } from "../../../constants"
import Image from 'next/image'
import ServiceContext from "../../../context/Services"
import WhatsApp from '@mui/icons-material/WhatsApp'
import Mail from '@mui/icons-material/Mail'
import YouTubeIcon from '@mui/icons-material/YouTube'
import TwitterIcon from '@mui/icons-material/Twitter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faVideoCamera } from '@fortawesome/free-solid-svg-icons'
import { faThreads, faTiktok } from '@fortawesome/free-brands-svg-icons'
import '@fortawesome/fontawesome-svg-core/styles.css'
function Footer() {
  const { t } = useTranslation("common")
  // const router = useRouter()
  const mobile = useMedia("(max-width: 580px)");
  const hakeemServicesList = useContext(ServiceContext)
  return (
    <div className={style.footercolor + " " + style.relativePosition}>
      <div className={style.footer}>

        <div className={style.cardf}>
          <div className={style.textfooter}>
            <div className={style.logof}>
              <Image src={`${config.basePath}/images/logo4.svg`}
                alt="hakeem-log"
                width={50}
                height={50}
              className={style.logof}
              />
            </div>
            <p>  <Link prefetch={false} href="/" passHref >{t("Home")}</Link> </p>
            <p> <Link prefetch={false} href={constants.about} passHref >{t("Abouthakeem")}</Link></p>
            <p>  <Link prefetch={false} href={constants.contactuslink} passHref >{t("ContactUs")}</Link></p>
            <p>  <Link prefetch={false} href={constants.termandconditionsLink} passHref >{t("TermandCondition")}</Link></p>
          </div>
        </div>
        <div className={style.cardf}>

          <div>
            <p className={style.bloodtextfooter}>{t("Hakeemmarket")}</p>
            {hakeemServicesList && hakeemServicesList.hakeemServices && hakeemServicesList.hakeemServices.length > 0 && hakeemServicesList.hakeemServices.filter(i => i.code == "product").length > 0 ?
              <p className={style.textfooter}> <Link prefetch={false} href={{ pathname: constants.HakeemProducts }} passHref > {t("HakeemProducts")}</Link></p> : ""}
            <p className={style.textfooter}> <Link href={{ pathname: constants.TrademarksLink }}> {t("Trademarks")}</Link></p>
            {hakeemServicesList && hakeemServicesList.hakeemServices && hakeemServicesList.hakeemServices.length > 0 && hakeemServicesList.hakeemServices.filter(i => i.code == "sponsor").length > 0 ?
              <p className={style.textfooter}> <Link prefetch={false} href={{ pathname: constants.sponsorsLink }}> {t("Advertising")}</Link></p> : ""}
          </div>
       </div>
        {mobile ? " " :
         <div className={style.cardf}>
          {hakeemServicesList && hakeemServicesList.hakeemServices && hakeemServicesList.hakeemServices.length > 0 && hakeemServicesList.hakeemServices.filter(i => i.code == "blogs").length > 0 ?
            <div>
              <p className={style.bloodtextfooter}>{t("HakeemBlogs")}</p>
              <p className={style.textfooter}>
                <Link prefetch={false} href={`${constants.blogsLink}/articles`}>

                  {t("Articals")}</Link>
              </p>
              <p className={style.textfooter}>
                <Link prefetch={false} href={`${constants.blogsLink}/news`}>
                  {t("News")}</Link>
              </p>

            </div> : ""}

        </div> }
        <div className={style.cardf}>

           <div className={style.textandsocial}>
        {mobile && hakeemServicesList && hakeemServicesList.hakeemServices && hakeemServicesList.hakeemServices.length > 0 && hakeemServicesList.hakeemServices.filter(i=>i.code=="blogs").length>0?
            <div>
              <p className={style.bloodtextfooter}>{t("HakeemBlogs")}</p>
              <p className={style.textfooter}>
                <Link prefetch ={false} href={`${constants.blogsLink}/articles`}>

                  {t("Articals")}</Link>
              </p>
              <p className={style.textfooter}>
                <Link prefetch ={false} href={`${constants.blogsLink}/news`}>
                  {t("News")}</Link>
              </p>
 
            </div>:""}

          {mobile ?
            " "
            : <div>
              <p className={style.bloodtextfooter}>{t("FollowUs")}</p>
              <div className={style.cardssocailfooter}>
                <div>
                  <a href="https://www.linkedin.com/company/75742143/" alt="hakeem linkedin" title="hakeem linkedin" target="_blank" rel="noreferrer"><LinkedIn className={style.FSocial} sx={{ color: "white" }} />
                  </a>
                </div>
                <div className={style.card}>
                  <a href="https://www.facebook.com/hakeem.app.official" alt="hakeem facebook " title="hakeem facebook" target="_blank" rel="noreferrer"><Facebook className={style.FSocial} sx={{ color: "white" }} />
                  </a>
                </div>
                <div className={style.card}>
                  <a href="https://t.me/hakeemplatform" alt="hakeem telegram" title="hakeem telegram" target="_blank" rel="noreferrer"><Telegram className={style.FSocial} sx={{ color: "white" }} />
                  </a>
                </div>
                <div className={style.card}>
                  <a href="https://www.instagram.com/hakeem.apps/" alt="hakeem instagram" title="hakeem instagram" target="_blank" rel="noreferrer"><InstagramIcon  className={style.FSocial} sx={{ color: "white" }} />
                  </a>
                </div>
                <div className={style.card}>
                  <a href="https://wa.me/967778876004 " alt="hakeem whatsapp" title="hakeem whatsapp" target="_blank" rel="noreferrer"><WhatsApp className={style.FSocial} sx={{ color: "white" }} />
                  </a>
                </div>
                <div className={style.card}>
                  <a href="mailto:marketing@hakeem.app" alt="hakeem mail" title="hakeem mail" target="_blank" rel="noreferrer"><Mail className={style.FSocial} sx={{ color: "white" }} />
                  </a>
                </div>
                <div className={style.card}>
                  <a href="https://www.youtube.com/channel/UCECN-Cqfn1LphIV2sJjc99w" alt="hakeem youtube" title="hakeem youtube" target="_blank" rel="noreferrer"><YouTubeIcon className={style.FSocial} sx={{ color: "white" }} />
                  </a>
                </div>
                <div className={style.card}>
                  <a href="https://twitter.com/HakeemAppOffici" alt="hakeem twitter" title="hakeem twitter" target="_blank" rel="noreferrer"><TwitterIcon className={style.FSocial} sx={{ color: "white" }} />
                  </a>
                </div>
                <div className={style.card}>
                  <a href="https://www.threads.net/@hakeem.apps" alt="hakeem twitter" title="hakeem threads" target="_blank" rel="noreferrer">   <FontAwesomeIcon className={style.FSocial} icon={faThreads} size={"xl"} />
                  </a>
                </div>
                <div className={style.card}>
                  <a href="https://www.tiktok.com/@hakeem.app" alt="hakeem twitter" title="hakeem tiktok" target="_blank" rel="noreferrer">   <FontAwesomeIcon className={style.FSocial} icon={faTiktok} size={"xl"} />
                  </a>
                </div>
              </div>
            </div>}
          </div> 
        </div>

      </div>
      {mobile ? <div className={style.cardf + " " + style.FollowMobile}>
          <div>
            <p className={style.bloodtextfooter}>{t("FollowUs")}</p>
            <div className={style.cardssocailfooter}>
              <div >
                <a href="hthttps://www.linkedin.com/company/75742143/" alt="hakeem linkedin" title="hakeem linkedin" target="_blank" rel="noreferrer">
                  <LinkedIn className={style.FSocial} sx={{ color: "white" }} />
                </a>
              </div>

              <div className={style.card}>

                <a href="https://www.facebook.com/hakeem.app.official" alt="hakeem facebook " title="hakeem facebook" target="_blank" rel="noreferrer"><Facebook  className={style.FSocial} sx={{ color: "white" }} />
                </a>
              </div>
              <div className={style.card}>
                <a href="https://t.me/hakeemplatform" alt="hakeem telegram" title="hakeem telegram" target="_blank" rel="noreferrer"><Telegram className={style.FSocial} sx={{ color: "white" }} />
                </a>
              </div>
              <div className={style.card}>
                <a href="https://www.instagram.com/hakeem.apps/" alt="hakeem instagram" title="hakeem instagram" target="_blank" rel="noreferrer"><InstagramIcon className={style.FSocial} sx={{ color: "white" }} />
                </a>
              </div>
              <div className={style.card}>
                <a href="tel:+967778876004 " alt="hakeem whatsapp" title="hakeem whatsapp" target="_blank" rel="noreferrer"><WhatsApp className={style.FSocial} sx={{ color: "white" }} />
                </a>
              </div>
              <div className={style.card}>

                <a href="mailto:marketing@hakeem.app" alt="hakeem mail" title="hakeem mail" target="_blank" rel="noreferrer"><Mail className={style.FSocial} sx={{ color: "white" }} />
                </a>
              </div>
              <div className={style.card}>

                <a href="https://www.youtube.com/channel/UCECN-Cqfn1LphIV2sJjc99w" alt="hakeem youtube" title="hakeem youtube" target="_blank" rel="noreferrer"><YouTubeIcon className={style.FSocial} sx={{ color: "white" }} />
                </a>
              </div>
              <div className={style.card}>

                <a href="https://twitter.com/HakeemAppOffici" alt="hakeem twitter" title="hakeem twitter" target="_blank" rel="noreferrer"><TwitterIcon className={style.FSocial} sx={{ color: "white" }} />
                </a>
              </div>
              <div className={style.card}>


                <a href="https://www.threads.net/@hakeem.apps" alt="hakeem threads" title="hakeem threads" target="_blank" rel="noreferrer">   <FontAwesomeIcon className={style.FSocial} icon={faThreads} size={"xl"} />
                </a>
              </div>
              <div className={style.card}>


                <a href="https://www.tiktok.com/@hakeem.app" alt="hakeem tiktok" title="hakeem tiktok" target="_blank" rel="noreferrer">   <FontAwesomeIcon className={style.FSocial} icon={faTiktok} size={"xl"} />
                </a>
              </div>

            </div>
          </div>
        </div> : ""}
        <div className='text-white text-center py-2'>
        {t("copyRight")} <a href="https://yottagate.com/" target='_blank' style={{textDecoration:"underline"}}>{t("yottagate")}</a> {t("allRightReserved")}
          
           </div>
     
    </div>

  )
}

export default Footer
