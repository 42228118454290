import { useEffect, useState } from 'react';


export const useMedia = (query: string, defaultState: boolean = false) => {
  const isClient = typeof window === 'object';
  const [state, setState] = useState(
    defaultState
  );

  useEffect(() => {

    let mounted = true;

    const mql = window.matchMedia(query);

    if (isClient) {
      setState(mql.matches)
    }
    else {
      setState(defaultState)
    }
    const onChange = () => {
      if (!mounted) {
        return;
      }
      setState(!!mql.matches);
    };

    mql.addListener(onChange);


  }, [query]);

  return state;
};
