import styled from '@mui/system/styled';
import Dialog from '@mui/material/Dialog'
export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      // paddingLeft: "10px",
      // paddingRight: "10px",
      paddingBottom: "12px",
      borderBottom: "none",
      overflow:"hidden"
      // width: "303px !important ",
    },
    '& .MuiDialogAction-root': {
      padding: theme.spacing(0),
    }
    ,
  
    '& .MuiDialog-paper': {
      maxWidth: "600px !important ",
      direction: "ltr",
      display: "flex",
      width:"100%",
      justifyContent: "center",
  
    },
    '@media (min-width:280px) and (max-width:767px)': {
      '& .MuiDialog-paper': {
        margin: "10px !important",
      },
      '& .MuiDialogContent-root': {
        paddingLeft: "10px",
        paddingRight: "10px",
      }
    },
    '@media (min-width:400px) ': {
        '& .MuiDialogContent-root': {
            // width: "385px !important ",
          },  
    },
    '@media (min-width:540px) ': {
        '& .MuiDialogContent-root': {
            // width: "520px !important ",
          },  
    },
    '@media (min-width:768px)': {
        '& .MuiDialogContent-root': {
            // width: "650px !important ",
          },
    },
    '@media (min-width:992px)': {
        '& .MuiDialogContent-root': {
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop:0,
            paddingBottom: "12px",
            borderBottom: "none",
            // width: "800px !important ",
          },
    }
  }))