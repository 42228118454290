import React, { useEffect, useState,useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Button,
  TextField,
  Autocomplete,
  Grid
} from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
import { useRouter } from "next/router";
// import { makeStyles } from "@mui/styles";
import FormControl, { Feedback } from "@mui/material/FormControl";
// import PropTypes from "prop-types";
// import lottie from "lottie-web";
import * as config from "../../../next.config.js";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/system";
import { useTranslation } from "next-i18next";
// import InputLabel from "@mui/material/InputLabel";
import FormHelperTxet from "@mui/material/FormHelperText";
import { printLogs } from "../../helpers/helpers";
import style from "../../../styles/mystyle.module.css";
import getIncludeStatusIds from "../../helpers/global/getIncludeStatusIds";
import axios from "axios";

import Cookie from "js-cookie";
import { encryption } from "../../helpers/helpers";
import TermsContext from "../../../context/Trems"
import {getImage}from '../../helpers/images'
import {CutomeDivRoot} from "../StyledComponent/HintDialog1.style"
import {Customebtn} from "../StyledComponent/HintDialog1.style"
import {CustomeFormControl} from "../StyledComponent/HintDialog1.style"
import {CutomeIcon} from "../StyledComponent/HintDialog1.style"



export default function CountryDialog(props) {
  const { t } = useTranslation("common");
  const vocabTerms= useContext(TermsContext)
  const { locale } = useRouter();
  const router = useRouter();
  const [inputList, setInputList] = useState({ country: "" });
  const [errors, setErrors] = useState([]);
  const [countriesList, setCountriesList] = useState([]);

  const [Images, setImages] = useState([]);
  const handleChangeCode = (e, value) => {

    printLogs("hakeemPay", value);
   
    const value2 = { ...inputList };
    if (value) {
      value2["country"] = value.id;
      setErrors({ ...errors, country: null });
      setInputList(value2);
      validateFrom();
    }
  };

  const validateFrom = () => {
    const newError = {};
    if (inputList.country == null || inputList.country == "") {
      newError.country = t("ErrorNtoe");
    }

    return newError;
  };

  const handleSend = e => {
    const fromError = validateFrom();

    if (Object.keys(fromError).length > 0) {
      setErrors(fromError);
    } else {
 
      let country = countriesList.filter(i => i.id == inputList.country)[0]
        .code;

      Cookie.set(
        encryption("userCountry"),
        encryption(JSON.stringify(country))
      );
      setTimeout(() => {
        window.location = config.basePath;
      }, 10);
    }
  };

  useEffect(() => {
  
if(props.open==true){
   
            let filesIds = [];
            if (vocabTerms.countriesService.length > 0) {
              let tmepResponse = vocabTerms.countriesService.filter(
                i =>
                i.language&&i.language.code == router.locale &&i.field2&&
                  JSON.parse(i.field2) != null &&
                  JSON.parse(i.field2).service == "true"
              );
              tmepResponse.map(i => {
                filesIds.push(JSON.parse(i.field2).fileId);
              });
              if (filesIds.length > 0) {
                axios
                  .get(`${config["basePath"]}/api/Files/getFiles`, {
                    headers: {
                      "Content-Type": "application/json"
                    },
                    params: {
                      fileIds: JSON.stringify(filesIds)
                    }
                  })
                  .then(response2 => {
                    if (response2.data.data.data.length > 0) {
                      let tmepFiles = response2.data.data.data;
         
                      tmepFiles.map(async x => {
                        if (x.filePath != null) {
                          
                            let img=await getImage( x.fileId,`${config.env.mediaUrl}${"/"}${x.filePath}`)
                              let resImage = [
                                { image: img, fileId: x.fileId }
                              ];
                              setImages(prev => [...prev, ...resImage]);
                              setCountriesList(tmepResponse);
                        }
                      });
                    }
                  })
                  .catch(err => (err));
              }
            }
          }
  }, [props.open]);
  useEffect(() => {
    setInputList({ country: "" });
  }, [props.reload]);
  return (
    <>
      <CutomeDivRoot>
        <div className={style.SectionContainer}>
          <div className={style.allcard}>
            <div className={style.countryDiv}>
              <CutomeIcon xs={10}>
                <img
                  className={style.logoCountryDilaog}
                  src={`${config.basePath}/assest/logo1.svg`}
                  alt={"hakeem-logo"}
                ></img>
              </CutomeIcon>
              {printLogs("countriesList", countriesList)}
              <div className={style.TextFieldAndBtn}>
                <CustomeFormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={(e, value) => handleChangeCode(e, value)}
                    options={countriesList && countriesList}
                    getOptionLabel={option => option.name}
                    style={{
                      textAlign: locale == "ar" ? "right" : "left",
                      justifyContent:
                        locale == "ar" ? "flex-end" : "flex-start",
                      flexDirection: locale == "ar" ? "row-reverse" : "row",
                      alignItems: locale == "ar" ? "right" : "left",
                      alignContent: locale == "ar" ? "flex-end" : "flex-start",
                      direction: locale == "ar" ? "rtl" : "ltr"
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        style={{
                          direction: router.locale == "ar" ? "rtl" : "ltr",
                          alignItems: "left"
                        }}
                        {...props}
                      >
                        <img
                          src={
                            Images.filter(
                              i => i.fileId == JSON.parse(option.field2).fileId
                            ).length > 0
                              ? Images.filter(
                                  i =>
                                    i.fileId == JSON.parse(option.field2).fileId
                                )[0].image
                              : ""
                          }
                          width="30px"
                        />
                        &nbsp; &nbsp;
                        {option.name}
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField {...params} label={t("Country")} />
                    )}
                  />
                  <FormHelperTxet error={errors.country != null ? true : false}>
                    {errors.country}
                  </FormHelperTxet>
                </CustomeFormControl>
                <Customebtn onClick={handleSend} variant="contained" color={"secondary"}>{t("btnOk")}</Customebtn>
              </div>
              <div>
                <img
                  className={style.loginimg}
                  src={`${config.basePath}/assest/login.svg`}
                />
              </div>
            </div>
          </div>
        </div>
      </CutomeDivRoot>
    </>
  );
}
