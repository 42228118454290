import React ,{useEffect}from 'react';
import { MenuItem ,CustomeFormControl } from './language-switcher.style';
import { useLocale } from '../../context/language/language.provider';
// import { LANGUAGE_MENU } from '../../site-settings/site-navigation';
import style from "../../styles/mystyle.module.css"
import { useRouter } from 'next/router';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import axios from 'axios'
import * as config from '../../next.config.js'
import {getCookieValue} from '../helpers/helpers'
import {i18n} from  'next-i18next'
const LanguageMenu = ({ onClick, locale ,languageLst}) => {

  return (
    <>
    {languageLst.map((item) => (
        <MenuItem  key={item.id} value={item.id}>
         <FormControl>
           <RadioGroup
             aria-labelledby="Change-language"
             defaultValue={item.name}
             name="radio-buttons-group"
             onChange={onClick}
           >
             <CustomeFormControl  value={item.code} 
             control={<Radio className={style.TealColor} checked={item.code==locale} />} label={item.name} />
           </RadioGroup>
         </FormControl >
        </MenuItem>
        
      ))}
    </>
  );
};

const LanguageSwitcher: React.FC<{}> = () => {
  const { locale, changeLanguage } = useLocale();
  const router = useRouter()
  const [languageLst,setLanguageLst]=React.useState([])
  const languageChangeHandler = (e) => {
    changeLanguage(e.target.value);
    router.push({ pathname: router.pathname, query: router.query }, router.asPath, { locale: e.target.value })
    if (getCookieValue("login")) {
    let data = {
      langId: languageLst.filter(ele=>ele.code==e.target.value)[0].id
    }

    axios.post(`${config['basePath']}/api/user/patchUser`, data)
      .then((resp) => {
        
        if (resp.data.data != null) {
        }
      }).catch(err => console.log(err))
    }
    // i18n.changeLanguage(e.target.value)
  };
  useEffect(()=>{
    var configLangugae = {
      method: 'get',
      url: config.basePath + '/api/globals/languages/get',
      headers: {
          'Content-Type': 'application/json'
      },
      params: {}
  }
    axios(configLangugae)
    .then((resp) => {
      // console.log("resp2=========>",resp.data.data)
      setLanguageLst(resp.data.data)
    
    
    }).catch(err => console.log("err=======>",err))
  },[locale,router.locale])
  return (
  
    <LanguageMenu onClick={languageChangeHandler} locale={locale} languageLst={languageLst} />
  );
};

export default LanguageSwitcher;
