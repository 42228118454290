
import config from '../../../next.config.js';
// import axios from 'axios'
import {getTermsByVocabCodes} from './term/term'

async function getIncludeStatusIds() {
  let response =await getTermsByVocabCodes(["maker-checker-entity-status","maker-checker-status","maker-only-status"])
             if (JSON.parse(response)) {
                 let    makercheckerentitystatus = JSON.parse(response).filter(i=>i.vocCode=="maker-checker-entity-status")
                 let  makercheckerstatus = JSON.parse(response).filter(i=>i.vocCode=="maker-checker-status")
                 let  makeronlystatus = JSON.parse(response).filter(i=>i.vocCode=="maker-only-status")
                 let templist2=makercheckerentitystatus.concat(makercheckerstatus).concat(makeronlystatus)  
                 let configStatus=JSON.parse(config.env.approvedStatus)['maker-checker-entity-status'].concat( JSON.parse(config.env.approvedStatus)['maker-checker-status']).concat( JSON.parse(config.env.approvedStatus)['maker-only-status'])
                 let Status= templist2.filter(o=>configStatus.includes(o.code))
                 const StatusIds= Status.map(i=>i.id)
                 return   StatusIds
             }
}
export default getIncludeStatusIds