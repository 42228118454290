import React, { useEffect, useState } from 'react';
import {
   Typography
} from "@mui/material";
import PropTypes from 'prop-types'
import lottie from "lottie-web";
import * as config from "../../../next.config.js"
import { useTranslation } from 'next-i18next'
import FormHelperTxet from '@mui/material/FormHelperText'
import {CustomeBox} from "../StyledComponent/HintDialog1.style" 
import {CustomeDialogContent} from "../StyledComponent/HintDialog1.style" 
import {CustomeDialogActions} from "../StyledComponent/HintDialog1.style" 
import {BootstrapDialog} from "../StyledComponent/HintDialog1.style" 
import {Customebtn} from "../StyledComponent/HintDialog1.style" 
import {CustomeTextField} from "../StyledComponent/HintDialog1.style" 
import {CustomeFormControl2} from "../StyledComponent/HintDialog1.style" 

export default function ConfirmDialog(props) {
  const { t } = useTranslation("common")
  const [inputList, setInputList] = useState([{ note: null }])
  const [errors, setErrors] = useState([])
  const validateFrom = () => {
    const newError = {}

    if (inputList[0].note == null || inputList[0].note == '' || inputList[0].note.length < 10) {
      newError.note = t("ErrorNtoe")
    }
    if (inputList[0].note != null && inputList[0].note.length < 10) {
      newError.note = t("validateNote")
    }
    return newError
  }
  const handleChangenote = (e) => {
    setErrors({ ...errors, note: null })
    const list = [...inputList];
    list[0]["note"] = event.target.value
    setInputList(list);

  }
  const handleSend = (e) => {
    const fromError = validateFrom()
    if (Object.keys(fromError).length > 0) {
      setErrors(fromError)
    }
    else {

      props.callBack(inputList, false)


    }

  }
  const lottieDiv = React.useRef()
  useEffect(() => {
    setErrors([])
    setInputList([{ note: null }])
    if (props.open) {
  
      setTimeout(() => {
      

       



      lottie.loadAnimation({
          container: lottieDiv.current,

          loop: true,
          autoplay: true,
          renderer: "svg",
          path: config.basePath + (props.type == "success" ? `/json/success.json` : "/json/error.json"),
          rendererSettings: {
            id: "imgSuccess"
          }
        })

        // lottie.play()
     
 
      })
   

    }
    // console.log("destroy()============>")


// return ()=>lottieDiv.current.destroy()
// return ()=>lottie.destroy()


return ()=>lottie.destroy()



  }, [props.open])
  return (
    <>
      <BootstrapDialog
        open={props.open}
        maxWidth={"xs"}
        fullWidth
        onClose={props.onClose}
        PaperProps={{
          sx: { overflow: "visible",  position: "relative" },

        }}
      >
        <CustomeBox
          ref={lottieDiv}
        ></CustomeBox>

        <CustomeDialogContent style={{ position: "relative", width: "100%" }}>
          <br />
          {props.title ? <Typography
            style={{ textAlign: "center", fontWeight: "bold",  color: "#F52A59", padding: "10px 0px" }}
            variant="h3"
          >


            {props.title}



          </Typography> : ""}
          <Typography
            style={{ textAlign: "center", fontWeight: "bold",  padding: "10px 0px" }}
            variant="h5"
          >
            {props.text}





          </Typography>
          {props.OpretionType && props.OpretionType == "cancel" ?
            <CustomeFormControl2
              style={{ direction: "rtl" }}
            >

              <CustomeTextField

                label={t("TheCause")}
                value={inputList[0].note}
                onChange={handleChangenote}
                sx={{
                  width: "100%",
                  margin: "0 ",
                  display: "flex !important",
                  borderRadius: 0,

                }}
              />
              <FormHelperTxet
                error={errors.note != null ? true : false}
                style={{ textAlign: "start" }} >
                {errors.note}
              </FormHelperTxet>
            </CustomeFormControl2> : ""}
        </CustomeDialogContent>
        <CustomeDialogActions
          justifyContent="center"
          style={{ justifyContent: "center" }}
        >
       

          <Customebtn
             variant={"contained"}
             color={"secondary"}
            loading={props.loading}
            onClick={props.OpretionType && props.OpretionType == "cancel" ? handleSend : props.btnOk}
          // onClick={handleSend}
          >
            {props.confirmBtnTitle}
            {/* {t("btnDelete")} */}
          </Customebtn>
          <Customebtn
            variant={"contained"}
            // sx={{ backgroundColor: "#F52A59 !important" }}
            onClick={props.btnCancel}
            loading={props.loading}
          >
            {t("btnCancel")}

          </Customebtn>
        </CustomeDialogActions>
        {props.children}
      </BootstrapDialog>
    </>
  );
}
ConfirmDialog.propTypes = {
  confirm: PropTypes.func,
  reject: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  text: PropTypes.string
}