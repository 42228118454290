import React, { useState, useEffect, useContext } from "react";
import { useMedia } from "../../../utils/use-media";
import style from "../../../styles/mystyle.module.css";
import { useTranslation } from "next-i18next";
import LanguageSwitcher from "../../language-switcher/language-switcher.tsx";
import * as config from "../../../next.config.js";
import Link from "next/link";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useRouter } from "next/router";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { decryptLocalStorage, getCookieValue, printLogs, encryption, encryptLocalStorage,setCookiesValues } from '../../helpers/helpers'
import { handleLogout, logOutUser, checkTocken } from "../../helpers/auth";
import { constants } from "../../../constants";
import Timer from '../Login/Timer'
import { useCart } from "../../../context/cart/use-cart";
import Badge from "@mui/material/Badge";
import Footer from "../../components/Layout/Footer"
import DownloadApp from '../../components/Home/DownloadApp'
import SquareFooter from '../../components/Layout/SquareFooter';
import Image from 'next/image'
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import Cookie from "js-cookie";
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
const ITEM_HEIGHT = 48;
import ServiceContext from "../../../context/Services"
import CurrencyContext from '../../../context/currencyProvider'
import SystemConfigrationContext from '../../../context/SystemConfigration'
import ListItemIcon from "@mui/material/ListItemIcon";
import Searchbarhomepage from '../Home/Searchbarhomepage'
import TermsContext from "../../../context/Trems"
import CountryDialog from '../../components/Dialogs/CountryDialog'
import { getImage } from '../../helpers/images'
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useQuery } from '@tanstack/react-query'
import Searchmobile from '../Home/Searchmobile'
import { Radio } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
// import HoverCategory from "../Home/HoverCategory";
import  dynamic from 'next/dynamic'

const HoverCategory =dynamic(()=>import("../Home/HoverCategory"),{ssr:false})
import OrderMealsCitiesDialog from '../../components/Dialogs/OrderMealsCitiesDialog'
// import Divider from '@mui/material/Divider';
import {buidlObject} from '../../helpers/helpers'
import { getTermsByVocabCodes } from "../../helpers/global/term/term";
const CustomeBtn = styled(IconButton)({
  "&.MuiIconButton-root:hover": {
    backgroundColor: "transparent"
  },
  '&.MuiIconButton-root': {
    color: "transparent",
    borderRadius: 0,
    padding: 0
  }
})
const CustomeBtn3 = styled(Button)({
  "&.MuiButton-root:hover": {
    backgroundColor: "transparent"
  },
})
const CustomeAvatar = styled(Avatar)({
  width: "1.5rem !important",
  height: "1.5rem !important",
  fontSize: "0.6em"
})
const CustomeFormLabel = styled(FormLabel)({
  fontSize: "1em"
})
const CustomeuserName = styled(MenuItem)({
  cursor:"default",
  borderBottom:"1px solid #6464644a",
  "&.MuiMenuItem-root:hover": {
    backgroundColor: "transparent !important",
  }
})

const CustomeBadge = styled(Badge)(({ theme, locale }) => ({
  "& .MuiCheckbox-root": {
    padding: "16px"
  },
  "& .MuiBadge-badge": {
    left: locale == "ar" ? "3px" : "-15px",
    right: locale == "ar" ? "10px" : "20px",
    top: "11px",
    border: "1px solid #fff",
    padding: "0px 4px",
    backgroundColor: "#008C8D ",
    color: "#fff",
    minWidth: "18px",
    width: "20px",
    height: "20px",
    minHeight: "18px",
    fontSize: "9px"
  },
  '@media only screen and  (min-width:280px){': {
    "& .MuiBadge-badge": {
      left: locale == "ar" ? "-14px" : "0px",
      right: locale == "ar" ? "10px" : "20px",
      width: "15px",
      height: "15px",
      border: "0px solid #fff",
      top: "-1px",
    }
  }
}));
const CustomeBtn2 = styled(Button)({
  "&.MuiButton-root:hover": {
    backgroundColor: "transparent"
  },
  color: "#0d6efd",
  padding: 0,
  justifyContent: "flex-end",
})
const CustomeRoot = styled("a")`
    &:hover .ulList{
        display:block
    }
,
 .ulList{
    display:none
  }
}`;

export const CustomeFormControl = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    fontSize: "0.9em"
  },
  '& .MuiSvgIcon-root': {
    fontSize: "0.9em"
  }



})
const CustomeMenu = styled(Menu)(({ theme, locale }) => ({
}));
export const Navbar = ({ logoutCallBack, showLogin, showDownloadAppSection, children, countries, redirectToHome, exchangeAllCurrenciesCallBack, currenciesCallBack }) => {
useQuery( { queryKey: ["app-terms"], queryFn:async()=>JSON.parse(await getTermsByVocabCodes([
    "maker-checker-status",
    "maker-checker-entity-status",
    "maker-only-status",
    "countries",
    "hakeem-services",
    "entity-types",
    "slug-entity-classes",
    "blog-types",
    // "days",
    // "view-types",
    // "statistic-entities",
    // "product-categories",
    // "entity-categories",
    // "cities",
    // "districts",
    // "majors-types",
    // "sub-majors-types"
  ])) })

  const router = useRouter();
  const { locale } = useRouter();
  const pid = router.query.pid;
  const [anchorEl, setAnchorEl] = React.useState();
  const [op, setop] = React.useState(false);
  const [opBlog, setopBlog] = React.useState(false);
  const open = Boolean(anchorEl);
  const [countriesList, setCountriesList] = useState([])
  const vocabTerms = useContext(TermsContext)

  const [hakeemServicesList, sethakeemServicesList] = useState([]);

  const handleopen = () => {
    setop(!op);
  };
  const handleopenBlog = () => {
    setopBlog(!opBlog);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = event => {
    setAnchorEl(null);
  };
  const mobile = useMedia("(max-width: 580px)");
  const tablet = useMedia("(max-width: 991px)");
  const desktop = useMedia("(min-width: 992px)");
  const [menu, setmenu] = React.useState(false);
  const [Images, setImages] = useState([]);
  const { t } = useTranslation("common");
  const handleToggleMenu = () => {
    setmenu(!menu);
  };
  const [decryptLocalStorageEle, setDecryptLocalStorageEle] = useState(null)
  const [isShowLogin, setIsShowLogin] = useState(false)
  const [openDialog, setDialog] = useState(false)

  const { items } = useCart();

  const [ProductsCounter, setproductsCounter] = useState()
  let Couunter = 0
  const [UserImage, setUserImage] = useState(null)
  useEffect(() => {

    if (items && items.length > 0 && items.filter(i => i.productUnitPrice != null && i.userCountry == getCookieValue("userCountry")).length > 0) {
      // console.log("counter==========>")

      let emptlist = items.filter(i => i.productUnitPrice != null && i.userCountry == getCookieValue("userCountry"))
      emptlist.map(i => {

        Couunter += i.quantity
      })
      setproductsCounter(Couunter)
    }
    else {
      setproductsCounter(0)
    }

  }, [items])

  React.useEffect(() => {

    if (getCookieValue("login")) {
      if (getCookieValue("login") && getCookieValue("login").image != null) {
        let fileString = getCookieValue("login").image.filePath
        let rest = fileString.substring(0, fileString.lastIndexOf("/") + 1)
        let last = fileString.substring(fileString.lastIndexOf("/") + 1, fileString.length)
        let fileThumbnai = (rest + "thumbnail250/" + last)
        getImage(getCookieValue("login").image.fileId, fileThumbnai).then(res => {
          setUserImage(res)
        })

      }

      setIsShowLogin(true)


    }
    else {
      setIsShowLogin(false)
    }
    setDecryptLocalStorageEle(decryptLocalStorage())
  }, [showLogin, isShowLogin])

const [citiesLst,setCitiesLst]=useState([])
const [entityCategoriesFoodLst,setEntityCategoriesFoodLst]=useState([])
const terms=useQuery({queryKey:["cities","entity-categories"],queryFn:async()=> JSON.parse(await getTermsByVocabCodes(["cities","entity-categories"]))})
  React.useEffect(() => {
   
    let StatusIds = vocabTerms.statusIds||[];
      
        setCitiesLst(terms.data&&terms.data.filter(ele=>StatusIds.includes(ele.statusId)&&ele.vocCode=="cities"&&ele.field1==getCookieValue("userCountry")&&ele.language.code==locale&&StatusIds.includes(ele.statusId)))
        let resLst=terms.data&&terms.data.filter(ele=>StatusIds.includes(ele.statusId)&&ele.vocCode=="entity-categories"&&ele.fieldConfig&&ele.fieldConfig!=""&&buidlObject(ele.fieldConfig).category=="food"&&ele.language.code==router.locale&&buidlObject(ele.field3).displayInSearch=='true')
        // console.log("resLst========>",terms)
        setEntityCategoriesFoodLst(resLst)
    
   
    if (vocabTerms.countries&&vocabTerms.countries.length > 0) {
      if (getCookieValue("userCountry")) {
        let Country = vocabTerms.countries.filter(i =>
          i.field2 && JSON.parse(i.field2) != null && JSON.parse(i.field2).service == "true" && i.code == getCookieValue("userCountry"))
        if (Country.length > 0) {
          setSelectimag(JSON.parse(Country[0].field2).fileId)
          let filesIds = [];
          let tmepResponse = vocabTerms.countries.filter(
            i =>
              i.language.code == router.locale &&
              i.field2 && JSON.parse(i.field2) != null &&
              JSON.parse(i.field2).service == "true"
          );
          tmepResponse.map(i => {
            filesIds.push(JSON.parse(i.field2).fileId);
          });

          if (filesIds.length > 1) {
            axios
              .get(`${config["basePath"]}/api/Files/getFiles`, {
                headers: {
                  "Content-Type": "application/json"
                },
                params: {
                  fileIds: JSON.stringify(filesIds)
                }
              })
              .then(response2 => {

                if (response2.data.data.data.length > 0) {

                  let tmepFiles = response2.data.data.data;

                  tmepFiles.map(x => {
                    if (x.filePath != null) {
                      let filePath = config.env.mediaUrl.concat("/").concat(x.filePath)
                      getImage(x.fileId, filePath).then(res => {

                        let resImage = [
                          { image: res, fileId: x.fileId }

                        ];
                        setImages(prev => [...prev, ...resImage]);
                      }).catch(err => {

                      })

                    }
                  });
                }
                setCountriesList(tmepResponse);
              })
              .catch(err => printLogs(err));



          }
         
          if (vocabTerms.hakeemServices.length > 0) {
            let Countrycode = Country[0].field4
            let hakeemServices2 = vocabTerms.hakeemServices.filter(i => i.field1 && i.field1.includes(Countrycode))
           
            sethakeemServicesList(hakeemServices2)
          }
        }


      }
      else {
        setDialog(true)
      }
    }
  }, [getCookieValue("userCountry"), locale,terms.status])

  const handleLogOutUser =  () => {
     checkTocken().then( res => {
      handleLogout()
      if (res.data.data.id == 0) {
     
        router.push("/user-ms/login")
        setIsShowLogin(false)
        setAnchorEl(null);

      }
      else {
         logOutUser().then(res2 => {
          // handleLogout()
          router.push("/user-ms/login")
          setIsShowLogin(false)
          setAnchorEl(null);

        }).catch(err => printLogs(err))
      }
    }
    ).catch(err => printLogs(err))
  }
  const [opuser, setopuser] = React.useState(false)
  const handleopenuser = () => {
    setopuser(!opuser)
  }
  const [counter2, setCounter2] = React.useState();
  const [activeAcounter, setActiveCounter] = React.useState(true);
  const [timerReset, resetTimer2] = React.useReducer(p => !p, false);

  const [anchorCountry, setAnchorCountry] = React.useState();
  const [anchorLCC, setAnchorLCC] = React.useState();
  const [anchorCurrency, setAnchorCurrency] = React.useState();
  const [anchorCity, setAncohrCity] = React.useState();
  const openCountry = Boolean(anchorCountry);
  const openCity=Boolean(anchorCity)
  const openLCC = Boolean(anchorLCC);
  const openCurrency = Boolean(anchorCurrency);
  const [selectImag, setSelectimag] = useState()
  const [DialogData, setDialogData] = useState({
    open: false,
    Type: " ",
    Text: ""
  })
  const [openCitiesDialog, setOpenCitiesDialog] = useState(false)
  const saveDataOfCountry = (value) => {
    let country = countriesList.filter(i => i.id == value.id)
    Cookie.set(encryption("userCountry"), encryption(JSON.stringify(country[0].code)))
    setSelectimag(JSON.parse(country[0].field2).fileId)
    setAnchorCountry(null)
    if (redirectToHome && redirectToHome == true) {
      router.push("/")
    }
    if (getCookieValue("login")) {
      let data = {
        countryOfUse: value.id
      }

      axios.post(`${config['basePath']}/api/user/patchUser`, data)
        .then((resp) => {

          if (resp.data.data != null) {
          }
        }).catch(err => console.log(err))
    }
  }
  const [selectedCityValue,setSelectedCityValue]=useState()
  const saveDataOfCity = (value) => {
    setAncohrCity(null)
    setSelectedCityValue(value.code)
    setCookiesValues("foodCity",JSON.stringify(value))
   
    if(router.query&&entityCategoriesFoodLst.map(ele=>ele.code).includes(router.query.entityType)&&router.pathname=="/profile"){

      router.push({ pathname: "/profile", query: {entityType:entityCategoriesFoodLst.map(ele=>ele.code), city: value.name,isFood:true } })
    }
 
  }
  const handleClickCountry = event => {

    setAnchorCountry(event.currentTarget);
  };
  const handleCloseCountry = () => {
    setAnchorCountry(null);
  };
    const handleClickCity= event => {
// console.log("long-button-3=========>",event)
    setAncohrCity(event.currentTarget);
  };
  const handleCloseCity = () => {
    setAncohrCity(null);
  };
  const handleClickCurrency = event => {

    setAnchorCurrency(event.currentTarget);
  };
  const handleCloseCurrency = () => {
    setAnchorCurrency(null);
  };
  const handleClickLCC = event => {

    setAnchorLCC(event.currentTarget);
  };
  const handleCloseLCC = () => {
    setAnchorLCC(null);
  };
  const handleClick2 = (Type) => {


    let Authorization = getCookieValue("login");
    if (Authorization == false) {

      setDialogData({
        open: true,
        Type: "login",
        Text: t("ttlLoginFirst")
      })

    } else {


      setTimeout(() => {
        window.location = Type == "medicine" ? config.basePath + constants.order_medicine + "/medicine" :
          config.basePath + constants.order_medicine + "/examination";
      }, 1000);

    }

  }
  useEffect(() => {
    const handleScroll = () => {
      resetTimer2()
    }
    if (typeof window != 'undefined') {
      window.addEventListener("scroll", handleScroll, { passive: true })
      return () => window.removeEventListener("scroll", handleScroll)
    }

  }, [timerReset]);
  function getCurrencies() {


    return axios
    .get(`${config["basePath"]}/api/accounts/currency/get`, {
      headers: {
        "Content-Type": "application/json"
      },
      params: {
        // statusId:vocabTerms.statusIds,
        active: 1
      }
    }).then(async res => {

      // setCurrenciesLst(res.data.data.data)
      // currenciesCallBack(res.data.data.data)

     return  await axios
        .get(`${config["basePath"]}/api/accounts/currency/exchangeAllCurrencies`, {
          headers: {
            "Content-Type": "application/json"
          },
          params: {
            currencyId: (decryptLocalStorage() == null || !decryptLocalStorage().currency) ? res.data.data.data.filter(ele => ele.local == true)[0].id : decryptLocalStorage().currency,
            fromCurrency: false
          }
        }).then(resp => {
  
          // setExchangeCurrenciesLst(res.data.data.data)
          if (res.data.data.data) {
            if (decryptLocalStorage() == null || !decryptLocalStorage().currency) {
  
              encryptLocalStorage("currency", res.data.data.data.filter(ele => ele.local == true)[0].id)
              setActiveCurrencyCode(res.data.data.data.filter(ele => ele.local == true)[0].code)
              setActiveCurrencyId(res.data.data.data.filter(ele => ele.local == true)[0].id)
              setActiveCurrency(res.data.data.data.filter(ele => ele.local == true).map(ele => ele.transCurrency.filter(d => d.language.code == locale)[0].name)[0])
            }
            else {
              setActiveCurrencyId(res.data.data.data.filter(ele => ele.id == decryptLocalStorage().currency)[0].id)
              setActiveCurrencyCode(res.data.data.data.filter(ele => ele.id == decryptLocalStorage().currency)[0].code)
              setActiveCurrency(res.data.data.data.filter(ele => ele.id == decryptLocalStorage().currency).map(ele => ele.transCurrency.filter(d => d.language.code == locale).length > 0 ? ele.transCurrency.filter(d => d.language.code == locale)[0].name : ele.transCurrency[0].name)[0])
            }
          }
          return {currencies:res.data.data.data,exchangeCurrencies:resp.data.data.data}
        }).catch(err => console.log(err))
  

    }).catch(err => console.log(err))
  }
  const currenciesRes = useQuery({ queryKey: ['currencies'], queryFn: getCurrencies })
  const [activeCurrency, setActiveCurrency] = useState()
  const [activeCurrencyCode, setActiveCurrencyCode] = useState()
  const [activeCurrencyId, setActiveCurrencyId] = useState()
  const [currenciesLst, setCurrenciesLst] = useState([])
  const [exchangeCurrenciesLst, setExchangeCurrenciesLst] = useState([])
  const  [medicalEntityTypesCodes,setMedcialEntityTypesCodes]=useState([])
  useEffect(() => {
  
      let tempEntityType=''
      vocabTerms.entityTypes&&  vocabTerms.entityTypes.filter(ele=>ele.fieldConfig&&ele.fieldConfig!=""&&buidlObject(ele.fieldConfig).category=="medical"&&ele.language.code==router.locale).map(ele=>{
        tempEntityType+=ele.code+","
      })
      setMedcialEntityTypesCodes(tempEntityType)
  }, [])
  function getExchangeAllCurrencies(currencyId2) {
    return axios
      .get(`${config["basePath"]}/api/accounts/currency/exchangeAllCurrencies`, {
        headers: {
          "Content-Type": "application/json"
        },
        params: {
          currencyId: currencyId2,
          fromCurrency: false
        }
      }).then(res => setExchangeCurrenciesLst(res.data.data.data)).catch(err => console.log(err))
  }
 
  const getExcludedPRoductsFromView = async () => {
    return await axios.post(`${config["basePath"]}/api/globals/systemConfigration/get`, {
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        limit: 100,
        "configKey": ["excludedProductsFromView"]
      }
    }).then(res => {
      return res.data.data

    }).catch(err => console.log(err))
  }
  const systemConfig = useQuery({ queryKey: ["excludedProductsFromView"], queryFn: async () => await getExcludedPRoductsFromView() })
  React.useEffect(()=>{
    if(currenciesRes.data&&currenciesRes.data.currencies){

 
    if (decryptLocalStorage() == null || !decryptLocalStorage().currency) {
  
      encryptLocalStorage("currency", currenciesRes.data.currencies.filter(ele => ele.local == true)[0].id)
      setActiveCurrencyCode(currenciesRes.data.currencies.filter(ele => ele.local == true)[0].code)
      setActiveCurrencyId(currenciesRes.data.currencies.filter(ele => ele.local == true)[0].id)
      setActiveCurrency(currenciesRes.data.currencies.filter(ele => ele.local == true).map(ele => ele.transCurrency.filter(d => d.language.code == locale)[0].name)[0])
    }
    else {
      setActiveCurrencyId(currenciesRes.data.currencies.filter(ele => ele.id == decryptLocalStorage().currency)[0].id)
      setActiveCurrencyCode(currenciesRes.data.currencies.filter(ele => ele.id == decryptLocalStorage().currency)[0].code)
      setActiveCurrency(currenciesRes.data.currencies.filter(ele => ele.id == decryptLocalStorage().currency).map(ele => ele.transCurrency.filter(d => d.language.code == locale).length > 0 ? ele.transCurrency.filter(d => d.language.code == locale)[0].name : ele.transCurrency[0].name)[0])
    }
  }
  },[currenciesRes.data])
  return (
    <>
      <SystemConfigrationContext.Provider value={systemConfig.data && systemConfig.data.length > 0 ? systemConfig.data : []}>
        <CurrencyContext.Provider value={{ currency: activeCurrencyId, exchangeCurrencyRes:currenciesRes.data&&currenciesRes.data.exchangeCurrencies, currenciesLst: currenciesRes.data&&currenciesRes.data.currencies||[] }}>
          {vocabTerms.countriesService && vocabTerms.countriesService.filter(ele => ele.language.code == locale).length > 1 && openDialog ?
            <CountryDialog
              countries={vocabTerms.countriesService}
              statusIds={vocabTerms.statusIds}
              open={openDialog}
            />
            : ""}
          <ServiceContext.Provider value={{ hakeemServices: hakeemServicesList }}>
            <div className={"navcolor " + " "}>
              <nav className={"nav"}>
                <div className=" min-w-24 min-h-16 w-20 h-16">
                  <Link href={"/"}
                  className="flex justify-center min-h-14 min-w-14 w-14 h-14 m-auto"
                  >
                    <Image
                      // className={style.logo}
                      src={`${config.basePath}/assest/navlogo.svg`}
                      alt={"hakeem-logo"}
                      layout="responsive"
                      width={50}
                      height={50}
                    ></Image>
                  </Link>

                </div>

                {(menu || desktop) ? (
                  <div className={(mobile || tablet) ? "navLinks" : "navLinksDesktop"}>
                    <div className="rightnav">
                      <Link href="/" passHref className={
                        ((mobile || tablet || menu) ? "animation1" : "") +
                        " " +
                        (router.pathname == "/" ? "active" : "")
                      }>

                        {t("Home")}

                      </Link>
                    {/* {console.log("hakeemServicesList=====>",hakeemServicesList)} */}
                      {desktop && hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "medical-services").length > 0 ? (
                        <span
                          className={
                            +" " +
                            ((mobile || tablet || menu)
                              ? "animation2"
                              : "" +
                              " " +
                              (router.pathname == "/book" ||
                                router.pathname == "/hakeemdiet" ||
                                router.pathname == "/order/[type]"
                                ? "active"
                                : ""))
                          }
                        >
                          <CustomeRoot as="span">
                            <CustomeBtn3
                              className={
                                "ButtonServices" + "  "
                              }
                              id="fade-Button-1"
                              aria-controls={open ? "fade-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onMouseMove={handleopen}
                            >
                              {t("HakeemServices")}
                              {/* <ExpandMoreIcon />{" "} */}
                            </CustomeBtn3>

                            <ul className={style.UlServicesDesktop + " ulList "}>

                              {hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "book-doctor").length > 0 ?
                                <Link

                                  href={{pathname:constants.HakeemMedical ,query:{entityType:medicalEntityTypesCodes,isMedical:true}}}

                                  passHref className={
                                    router.query.entityType == medicalEntityTypesCodes
                                      ? "active" + " " + "bgactive"
                                      : ""
                                  }>

                                  {t("Doctor")}

                                </Link> : ""}


                              {hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "order-meals").length > 0 ?


                                <button onClick={() => {
                                 
                                  if(getCookieValue("foodCity")==false){
                                    setOpenCitiesDialog(true)
                                  }
                                  else{
                                    let tempValue=''
                                    entityCategoriesFoodLst.map(ele=>{
                                      if(entityCategoriesFoodLst.length>1){
                                        tempValue += ele.code+ ","
                                      }
                                      else{
                                        tempValue += ele.code 
                                      }
                                     
                                    })
                                    router.push({ pathname: "/profile", query: {entityType:tempValue, city: getCookieValue("foodCity").name,isFood:true } })
                                  }
                                }
                                
                                }

                                  className={
                                    JSON.parse(config.env.HakeemDietCodes).includes(router.query.entityType)
                                      ? "active" + " " + "bgactive"
                                      : ""
                                  }
                                  style={{ padding: 0, width: "100%", background: "none", textAlign: "unset" }}
                                >
                                  {t("yourMeal")}
                                </button>

                                : ""}


                              {hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "shopping").length > 0 ?
                                <Link href={"/" + constants.productLink} className={
                                  router.asPath == constants.HakeemProducts

                                    ? "active" + " " + "bgactive"
                                    : ""
                                }>


                                  {t("Shopping")}

                                </Link> : ""}

                              <div
                                onClick={() => {
                                  if (getCookieValue("login") != false) {
                                    router.push(constants.PurchaseOrder)

                                  } else {
                                    handleClick2()
                                  }
                                }}
                                style={{ padding: 0, cursor: "pointer" }}
                              >


                                {t("Jeza3")}

                              </div>

                            </ul>
                          </CustomeRoot>{" "}
                        </span>
                      ) : (
                          ""
                        )}

                      {mobile && hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "medical-services").length > 0 || tablet && hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "medical-services").length > 0 ? (
                        <span
                          className={
                            (mobile || tablet || menu)
                              ? "animation2"
                              : "" +
                              " " +
                              (router.pathname === "/book" ||
                                router.pathname == "/profile"

                                ? "active"
                                : "")
                          }
                          style={{ paddingBottom: "20px !important" }}
                        >
                          <Button className="ButtonServices" onClick={handleopen} >
                            {t("HakeemServices")}
                            <ExpandMoreIcon />
                          </Button>

                          {op ? (
                            <ul className={style.UlServices + " " + style.BgUlServices}>
                              {hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "book-doctor").length > 0 ?

                                <Link

                                  className={
                                    router.query.entityType == "private-clinic,medicla-center,medical-center-clinic,hospital-clinic"
                                      ? "active" + " " + "bgactive"
                                      : ""
                                  }
                                  href={constants.HakeemMedical + "?entityType=private-clinic,medicla-center,medical-center-clinic,hospital-clinic"}
                                >

                                  <li>{t("Doctor")} </li>

                                </Link>

                                : ""}


                                    {(()=>{
                                      let tempValue=''
                                      entityCategoriesFoodLst.map(ele=>{
                                        if(entityCategoriesFoodLst.length>1){
                                          tempValue += ele.code+ ","
                                        }
                                        else{
                                          tempValue += ele.code 
                                        }
                                       
                                      }) 
 return hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "order-meals").length > 0 ?
 <Link href={{pathname:"/profile",query:{entityType:tempValue,city:getCookieValue("foodCity").name,isFood:true}}} className={
   JSON.parse(config.env.HakeemDietCodes).includes(router.query.entityType)
     ? "active" + " " + "bgactive"
     : ""
 }>


   <li>{t("yourMeal")}</li>

 </Link> : ""
                                    })()}
                             
                              {hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "shopping").length > 0 ?
                                <Link href={"/" + constants.productLink} className={
                                  router.asPath == constants.productLink ?
                                    "active" + " " + "bgactive"
                                    : ""
                                }>


                                  <li>{t("Shopping")}</li>

                                </Link> : ""}
                              {/* {hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "Jeza3").length > 0 ? */}

                              <div
                                className={
                                  (router.asPath == constants.PurchaseOrder ?
                                    "active" + " " + "bgactive"
                                    : "") + " " + "Jeza3"
                                }
                                onClick={() => {
                                  if (getCookieValue("login") != false) {
                                    router.push(constants.PurchaseOrder)

                                  } else {
                                    handleClick2()
                                  }
                                }}>

                                <li> {t("Jeza3")}</li>

                              </div>
                              {/* : ""} */}
                            </ul>
                          ) : (
                              ""
                            )}
                        </span>
                      ) : (
                          ""
                        )}

                      {desktop && hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "blogs").length > 0 ? (

                        <span
                          className={
                            mobile || tablet || menu
                              ? "animation3"
                              : "" +
                              " " +
                              (router.query.type == "articles" ||
                                router.query.type == "news"

                                ? "active"
                                : "")
                          }
                        >
                          <CustomeRoot as="span">
                            <CustomeBtn3
                              className={
                                "ButtonServices" + "  "
                              }
                              id="fade-Button-2"
                              aria-controls={open ? "fade-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onMouseMove={handleopen}
                            >
                              {t("HakeemBlogs")}
                              {/* <ExpandMoreIcon />{" "} */}
                            </CustomeBtn3>

                            <ul className={style.UlServicesDesktop + " ulList "}>

                              <Link

                                href={`${constants.blogsLink} `}
                                className={
                                  " " +
                                  (router.asPath == "/blogs"
                                    ? "active" + " " + "bgactive"
                                    : "")
                                }
                              >

                                <li> {t("Articals")}</li>

                              </Link>


                              <Link

                                href={`${constants.blogsLink + constants.newsLink}`}
                                className={
                                  " " +
                                  (router.query.type == "news"
                                    ? "active" + " " + "bgactive"
                                    : "")
                                }
                              >

                                <li>{t("News")}</li>

                              </Link>

                            </ul>
                          </CustomeRoot>{" "}
                        </span>
                      ) : (
                          ""
                        )}

                      {tablet || mobile && hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "blogs").length > 0 || tablet && hakeemServicesList && hakeemServicesList.hakeemServices && hakeemServicesList.hakeemServices.length > 0 && hakeemServicesList.hakeemServices.filter(i => i.code == "blogs").length > 0 ? (
                        <span
                          className={
                            mobile || tablet || menu
                              ? "animation3"
                              : "" +
                              " " +
                              (router.query.type == "articles" ||
                                router.query.type == "news"

                                ? "active"
                                : "")
                          }
                        >
                          <Button className="ButtonServices" onClick={handleopenBlog}>
                            {t("HakeemBlogs")}
                            <ExpandMoreIcon />
                          </Button>

                          {opBlog ? (
                            <ul className={style.UlServices + " " + style.BgUlServices}>

                              <Link

                                href={{
                                  pathname: constants.Blog,
                                  query: { type: "articles" },
                                  locale: locale
                                }}
                                className={
                                  " " +
                                  (router.asPath == "/blogs" ? "active" : "")
                                }
                              >

                                <li>
                                  {t("Articals")}
                                </li>

                              </Link>


                              <Link

                                className={
                                  " " +
                                  (router.query.type == "news"
                                    ? "active" + " " + "bgactive"
                                    : "")
                                }
                                href={`${constants.blogsLink + constants.newsLink}`}
                              >

                                <li>{t("News")}</li>

                              </Link>

                            </ul>
                          ) : (
                              ""
                            )}
                        </span>
                      ) : (
                          ""
                        )}

                      <Link href="/about" passHref className={
                        mobile || tablet || menu
                          ? "animation4"
                          : "" + " " + (router.pathname == "/about" ? "active" : "")
                      }>

                        {t("Abouthakeem")}

                      </Link>

                      <Link href={`${constants.contactuslink}`} className={
                        mobile || tablet || menu
                          ? "animation5"
                          : "" +
                          " " +
                          (router.pathname == "/contact-us" ? "active" : "")
                      }>


                        {t("ContactUs")}

                      </Link>

                    </div>

                    <div className="leftnav">
                      {desktop ? <Searchbarhomepage /> : ""}
                      {desktop ? (
                        <div className={style.stylebasketnav}>
                          <>
                            <CustomeBtn2
                              aria-label="more"
                              name={"more"}
                              id="long-button"
                              aria-controls={openLCC ? 'long-menu' : undefined}
                              aria-expanded={openLCC ? 'true' : undefined}
                              aria-haspopup="true"
                              onClick={handleClickLCC}
                              className="language"
                              style={{ minWidth: "45px" }}
                            >

                              {vocabTerms.countriesService && vocabTerms.countriesService.filter(ele => ele.language.code == locale).length > 1 ? Images.filter(
                                i => i.fileId == selectImag
                              ).length > 0
                                && Images.filter(
                                  i =>
                                    i.fileId == selectImag
                                )[0].image ?

                                <img

                                  className={style.basketnav}
                                  src={
                                    Images.filter(
                                      i => i.fileId == selectImag
                                    ).length > 0
                                      ? Images.filter(
                                        i =>
                                          i.fileId == selectImag
                                      )[0].image
                                      : ""
                                  }
                                  alt={"country"}

                                />
                                :
                                <CustomeAvatar className={style.basketnav} alt="Country">{vocabTerms.countriesService.filter(ele => ele.code == getCookieValue("userCountry"))[0]
                                  .field4} </CustomeAvatar>
                                : ""
                              }
                              <div className={style.FlexPM}>
                                {router.locale}
                                <ExpandMoreIcon />
                                </div>
                            </CustomeBtn2>
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                'aria-labelledby': 'long-button',
                              }}

                              anchorEl={anchorLCC}
                              open={openLCC}
                              onClose={handleCloseLCC}
                              PaperProps={{
                                style: {
                             
                                  width: '28ch',
                                  direction: router.locale == "ar" ? "rtl" : "ltr",
                                  alignItems: "right",
                                  padding: "0px 10px",
                                  maxHeight: "100%"
                                },
                              }}
                            >

                              <FormControl>
                                <CustomeFormLabel id="Change-language" className={style.flex + " " + style.halfGap}> {t("change-lan")} </CustomeFormLabel>
                                <LanguageSwitcher />
                              </FormControl>
                              <hr className={style.marginZero} />
                              <FormControl>
                                <CustomeFormLabel id="Change-Currency" className={style.flex + " " + style.halfGap + " " + style.paddingDialogLCCT}> {t("change-currency")}</CustomeFormLabel>
                                <CustomeFormLabel className={style.flex + " " + style.halfGap + " " + style.paddingDialogLCCB + " " + style.paddingDialogLCCT}  >
                                  {activeCurrency||currenciesRes.data&&currenciesRes.data.currencies.filter(ele => ele.local == true).map(ele => ele.transCurrency.filter(d => d.language.code == locale)[0].name)[0]}
                                  <CustomeBtn2
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={openCurrency ? 'long-menu' : undefined}
                                    aria-expanded={openCurrency ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClickCurrency}
                                  >
                                    {t("Change")}
                                  </CustomeBtn2></CustomeFormLabel>
                                <Menu
                                  id="long-menu"
                                  MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                  }}

                                  anchorEl={anchorCurrency}
                                  open={openCurrency}
                                  onClose={handleCloseCurrency}
                                  PaperProps={{
                                    style: {
                                      maxHeight: ITEM_HEIGHT * 4.5,
                                      width: '20ch',
                                    },
                                  }}
                                >
                                  {currenciesRes.data ?currenciesRes.data.currencies.map((ele, index) => {
                                    return <MenuItem
                                      style={{
                                        direction: router.locale == "ar" ? "rtl" : "ltr",
                                        alignItems: "right"
                                      }}
                                      onClick={() => {
                                        // console.log("click==========>")
                                        setAnchorCurrency(null);
                                        // currenciesRes.refetch(ele.id)
                                        // getExchangeAllCurrencies(ele.id)
                                        encryptLocalStorage("currency", ele.id)
                                        currenciesRes.refetch(ele.id)
                                        if (ele.transCurrency.filter(d => d.language.code == locale).length > 0) {
                                          setActiveCurrency(ele.transCurrency.filter(d => d.language.code == locale)[0].name)
                                        }
                                        else {
                                          setActiveCurrency(ele.transCurrency[0].name)
                                        }

                                        setActiveCurrencyCode(ele.code)
                                        setActiveCurrencyId(ele.id)
                                      }}
                                      key={index}
                                    >
                                      {ele.transCurrency.filter(d => d.language.code == locale).length > 0 ?
                                        <CustomeFormControl
                                          control={<Radio className={style.TealColor} id={`${ele.transCurrency[0].id}`}
                                            checked={ele.transCurrency.filter(d => d.language.code == locale)[0].name === activeCurrency} />
                                          }
                                          label={ele.transCurrency.filter(d => d.language.code == locale)[0].name}
                                        /> : <CustomeFormControl
                                          control={<Radio className={style.TealColor} id={`${ele.transCurrency[0].id}`}
                                            checked={ele.transCurrency[0].name === activeCurrency} />
                                          }
                                          label={ele.transCurrency[0].name}
                                        />}
                                    </MenuItem>
                                  }) : ""}





                                </Menu>
                              </FormControl>

                              {/* <div className={style.paddingDialogLCCT}> </div> */}
                              {vocabTerms.countriesService && vocabTerms.countriesService.filter(ele => ele.language.code == locale).length > 1 ? <hr className={style.marginZero + " " + style.paddingDialogLCCT} /> : ""}
                              {vocabTerms.countriesService && vocabTerms.countriesService.filter(ele => ele.language.code == locale).length > 1 ?
                                <div className={style.flex + " " + style.GapCou}>
                                  {Images.filter(
                                    i => i.fileId == selectImag
                                  ).length > 0
                                    && Images.filter(
                                      i =>
                                        i.fileId == selectImag
                                    )[0].image ?
                                    <img

                                      className={style.basketnav}
                                      src={
                                        Images.filter(
                                          i => i.fileId == selectImag
                                        ).length > 0
                                          ? Images.filter(
                                            i =>
                                              i.fileId == selectImag
                                          )[0].image
                                          : ""
                                      }
                                      alt={"country"}

                                    /> :
                                    <CustomeAvatar className={style.basketnav} alt="Country">{vocabTerms.countriesService.filter(ele => ele.code == getCookieValue("userCountry"))[0]
                                      .field4} </CustomeAvatar>
                                  }
                                  <CustomeBtn2
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={openCountry ? 'long-menu' : undefined}
                                    aria-expanded={openCountry ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClickCountry}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {t("change-country")}
                                  </CustomeBtn2>
                                </div>
                                : ""}
                                {/* cities====== */}
                                <div className={style.paddingDialogLCCT}> </div>
                                <hr className={style.marginZero + " " + style.paddingDialogLCCT} /> 
                                  {citiesLst&&citiesLst.length>0?  <CustomeBtn2
                                    aria-label="more"
                                    id="long-button-3"
                                    aria-controls={openCity ? 'long-button-3' : undefined}
                                    aria-expanded={openCity ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClickCity}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                   <div>{t("combo-city-label")} </div>
                                  </CustomeBtn2>:""}
                                  <Menu
                                  id="long-button-3"
                                  locale={router.locale}
                                  MenuListProps={{
                                    'aria-labelledby': 'long-button-3',
                                  }}

                                  anchorEl={anchorCity}
                                  open={openCity}
                                  onClose={handleCloseCity}
                                  PaperProps={{
                                    style: {
                                      maxHeight: ITEM_HEIGHT * 4.5,
                                      width: '20ch',

                                    },
                                  }}
                                >
                                 
                                  {citiesLst&&citiesLst.map((option, index) => (

                                    <MenuItem
                                      style={{
                                        direction: router.locale == "ar" ? "rtl" : "ltr",
                                        alignItems: "right"
                                      }}
                                      key={index} 
                                      onClick={() => saveDataOfCity(option)}
                                      selected={getCookieValue("foodCity").code == option.code||selectedCityValue==option.code}
                                    >

                                     
                                      {option.name}
                                    </MenuItem>
                                  ))}


                                </Menu>
                              {vocabTerms.countriesService && vocabTerms.countriesService.filter(ele => ele.language.code == locale).length > 1 ?
                                <Menu
                                  id="long-menu"
                                  locale={router.locale}
                                  MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                  }}

                                  anchorEl={anchorCountry}
                                  open={openCountry}
                                  onClose={handleCloseCountry}
                                  PaperProps={{
                                    style: {
                                      maxHeight: ITEM_HEIGHT * 4.5,
                                      width: '20ch',

                                    },
                                  }}
                                >

                                  {countriesList.map((option, index) => (

                                    <MenuItem
                                      style={{
                                        direction: router.locale == "ar" ? "rtl" : "ltr",
                                        alignItems: "right"
                                      }}
                                      key={index} onClick={() => saveDataOfCountry(option)}
                                      selected={getCookieValue("userCountry") == option.code}
                                    >

                                      <ListItemIcon>
                                        {Images.filter(
                                          i => i.fileId == JSON.parse(option.field2).fileId
                                        ).length > 0
                                          && Images.filter(
                                            i =>
                                              i.fileId == JSON.parse(option.field2).fileId
                                          )[0].image ?
                                          <img
                                            className={style.selectCountry}
                                            src={
                                              Images.filter(
                                                i => i.fileId == JSON.parse(option.field2).fileId
                                              ).length > 0
                                                ? Images.filter(
                                                  i =>
                                                    i.fileId == JSON.parse(option.field2).fileId
                                                )[0].image
                                                : ""
                                            }
                                            alt={"country"}

                                          /> : <CustomeAvatar className={style.basketnav} alt="Country">{option.field4} </CustomeAvatar>}

                                      </ListItemIcon>
                                      {option.name}
                                    </MenuItem>
                                  ))}


                                </Menu>
                                : ""}
                            </Menu>


                          </>

                          {hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "basket").length > 0 ?
                            <div>

                              <span className={style.AtagIcon} onClick={() => router.push("/basket")} style={{ cursor: "pointer" }}>

                                <CustomeBadge locale={locale} badgeContent={ProductsCounter > 0 ? ProductsCounter : null}>

                                  <div className={style.basketnav}>
                                    <Image
                                      className={style.basketnav}
                                      src={`${config.basePath}/assest/navbasket.svg`}
                                      alt={"hakeem-basket"}
                                      width={50}
                                      height={50}
                                    />
                                  </div>
                                </CustomeBadge>

                              </span>


                            </div>
                            : ""}
                          {hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "favorites").length > 0 ?
                            <div>

                              <span className={style.AtagIcon} onClick={() => router.push(constants.favoritesLink)} style={{ cursor: "pointer" }}>
                                {" "}
                                <div className={style.basketnav}>
                                  <Image
                                    className={style.basketnav}
                                    src={`${config.basePath}/assest/navfav.svg`}
                                    alt={"hakeem-favorite"}
                                    width={100}
                                    height={100}
                                  />
                                </div>
                              </span>

                            </div> : ""}
                        </div>
                      ) : (
                          ""
                        )}

                      {desktop ? (
                        <div className={style.loginandlanguage}>
                          {" "}

                          {isShowLogin == true ? (
                            <CustomeBtn
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={handleClick}
                            >

                              <span className={(style.clickSignin) + " " +
                                (mobile || tablet || menu ? "animation6" : "")
                              }>

                                {/* // {decryptLocalStorageEle && decryptLocalStorageEle["fullName"].length > 27 ?decryptLocalStorageEle["fullName"].substr(0, 27)+"...": decryptLocalStorageEle["fullName"]} */}


                                <Avatar
                                  alt={"Profile"}
                                  className={
                                    style.basketnav + " " + style.Cursor + " " + style.ProfileUser
                                  }
                                  src={UserImage ? UserImage : ""}
                                >
                                </Avatar>




                              </span>


                            </CustomeBtn>
                          ) : (

                              <button
                                className={style.signIn + " " + (mobile || tablet || menu ? "animation6" : "")}
                                onClick={() => router.push(constants.loginLink)}
                              >
                                {t("Singin")}
                              </button>

                            )}
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button"
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 5.5,
                                width: "20ch"
                              }
                            }}
                          >
                                 <CustomeuserName
                              style={{
                                direction: router.locale == "ar" ? "rtl" : "ltr",
                                alignItems: "right"
                              }}

                            >

                              {getCookieValue("login") && getCookieValue("login").officialName != null ? getCookieValue("login").officialName : decryptLocalStorageEle && decryptLocalStorageEle["fullName"] ? decryptLocalStorageEle && decryptLocalStorageEle["fullName"].length > 27 ? decryptLocalStorageEle["fullName"].substr(0, 27) + "..." : decryptLocalStorageEle["fullName"] : ""}

                              </CustomeuserName>
                            {/* <Divider /> */}
                            <MenuItem
                              style={{
                                direction: router.locale == "ar" ? "rtl" : "ltr",
                                alignItems: "right"
                              }}
                              onClick={() =>
                                setTimeout(() => {
                                  window.location = config.basePath + constants.dashboardUrl

                                }, 10)

                              }
                            >

                              {t("dashboard")}

                            </MenuItem>
                            {hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "reservation").length > 0 ?
                              <MenuItem
                                style={{
                                  direction: router.locale == "ar" ? "rtl" : "ltr",
                                  alignItems: "right"
                                }}
                                onClick={() => router.push(
                                  constants.myReservationsLink)}
                              >

                                {t("myReservations")}

                              </MenuItem> : ""}
                            <MenuItem
                              style={{
                                direction: router.locale == "ar" ? "rtl" : "ltr",
                                alignItems: "right"
                              }}
                              onClick={() => router.push(constants.view_addresses)}
                            >

                              {t("AddressesDelivery")}

                            </MenuItem>
                            <MenuItem
                              style={{
                                direction: router.locale == "ar" ? "rtl" : "ltr",
                                alignItems: "right"
                              }}
                              onClick={() => router.push(constants.changePassworLink)}
                            >

                              {t("ChangePassword")}

                            </MenuItem>
                            <MenuItem
                              style={{
                                direction: router.locale == "ar" ? "rtl" : "ltr",
                                alignItems: "right"
                              }}
                              onClick={ () =>  handleLogOutUser()}
                            >

                              {t("Logout")}

                            </MenuItem>
                            <MenuItem
                              style={{
                                direction: router.locale == "ar" ? "rtl" : "ltr",
                                alignItems: "right"
                              }}
                              onClick={() => router.push(constants.deleteAccountUrl)}
                            >

                              {t("deleteAccount")}

                            </MenuItem>

                          </Menu>


                        </div>
                      ) : (
                          ""
                        )}
                      {(mobile || tablet) ? <div className="langauage">

                        {isShowLogin == false ?

                          <Link href={constants.loginLink}
                            passHref className={style.signIn + " " + (mobile || tablet) || menu ? "animation7" : ""}>
                            {t("Singin")}</Link>
                          :
                          ""

                        }

                        {/* {isShowLogin == true && opuser ?
                          <ul className={style.UlServices + " " + style.BgUlServices}  >
                            <a><li className={style.AtagIcon}
                              style={{
                                direction: router.locale == "ar" ? "rtl" : "ltr",
                                alignItems: "right",

                                textDecoration: "none",
                                color: " #F52A59",
                                fontSize: "1.1rem",
                                fontWeight: 600,
                                paddingBottom: "20px",

                                transition: "0.5s ease",
                                transform: "translateX(0%)",

                              }}
                              onClick={() => router.push(
                                constants.dashboardUrl)}
                            >

                              {t("dashboard")}

                            </li></a>
                            {hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "reservation").length > 0 ?
                              <a><li className={style.AtagIcon}
                                style={{
                                  direction: router.locale == "ar" ? "rtl" : "ltr",
                                  alignItems: "right",

                                  textDecoration: "none",
                                  color: " #F52A59",
                                  fontSize: "1.1rem",
                                  fontWeight: 600,
                                  paddingBottom: "20px",

                                  transition: "0.5s ease",
                                  transform: "translateX(0%)",

                                }}
                                onClick={() => router.push(
                                  constants.myReservationsLink)}
                              >

                                {t("myReservations")}

                              </li></a> : ""}
                            <a><li className={style.AtagIcon}
                              style={{
                                alignItems: "right",

                                textDecoration: "none",
                                color: " #F52A59",
                                fontSize: "1.1rem",
                                fontWeight: 600,
                                paddingBottom: "20px",
s
                                transition: "0.5s ease",
                                transform: "translateX(0%)",
                              }}
                              onClick={async () => await handleLogOutUser()}
                            >{t("Logout")}

                            </li></a>

                          </ul> : ""} */}

                      </div> : ""}

                    </div>
                  </div>
                ) : (
                    ""
                  )}

                {mobile || tablet ? (
                  <div className={style.stylebasketnav}>
                    {/* start profile */}
                    <div className="langauage">

                      {isShowLogin == true ? (
                        <CustomeBtn
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={handleClick}
                        >

                          <span className={(style.clickSignin) + " " +
                            (mobile || tablet || menu ? "animation6" : "")
                          }>

                            <Avatar
                              alt={"Profile"}
                              className={
                                style.basketnav + " " + style.Cursor + " " + style.ProfileUser
                              }
                              src={UserImage ? UserImage : ""}
                            >
                            </Avatar>




                          </span>


                        </CustomeBtn>
                      ) : (

                          ""

                        )}
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button"
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "20ch"
                          }
                        }}
                      >
                        <CustomeuserName
                          style={{
                            direction: router.locale == "ar" ? "rtl" : "ltr",
                            alignItems: "right",
                            height: "30px",
                            fontSize:"1em"
                          }}
                        >
                          {getCookieValue("login") && getCookieValue("login").officialName != null ? getCookieValue("login").officialName : decryptLocalStorageEle && decryptLocalStorageEle["fullName"] ? decryptLocalStorageEle && decryptLocalStorageEle["fullName"].length > 27 ? decryptLocalStorageEle["fullName"].substr(0, 27) + "..." : decryptLocalStorageEle["fullName"] : ""}
                          </CustomeuserName>
                        {/* <Divider /> */}
                        <MenuItem
                          style={{
                            direction: router.locale == "ar" ? "rtl" : "ltr",
                            alignItems: "right",
                            height: "30px",
                            fontSize:"1em"
                          }}
                          onClick={() =>
                            setTimeout(() => {
                              window.location = config.basePath + constants.dashboardUrl

                            }, 10)

                          }
                        >

                          {t("dashboard")}

                        </MenuItem>
                        {hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "reservation").length > 0 ?
                          <MenuItem
                            style={{
                              direction: router.locale == "ar" ? "rtl" : "ltr",
                              alignItems: "right",
                              height: "30px",
                              fontSize:"1em"
                            }}
                            onClick={() => router.push(
                              constants.myReservationsLink)}
                          >

                            {t("myReservations")}

                          </MenuItem> : ""}
                        <MenuItem
                          style={{
                            direction: router.locale == "ar" ? "rtl" : "ltr",
                            alignItems: "right",
                            height: "30px",
                            fontSize:"1em"
                          }}
                          onClick={() => router.push(constants.view_addresses)}
                        >

                          {t("AddressesDelivery")}

                        </MenuItem>
                        <MenuItem
                          style={{
                            direction: router.locale == "ar" ? "rtl" : "ltr",
                            alignItems: "right",
                            height: "30px",
                            fontSize:"1em"
                          }}
                          onClick={() => router.push(constants.changePassworLink)}
                        >

                          {t("ChangePassword")}

                        </MenuItem>
                        <MenuItem
                          style={{
                            direction: router.locale == "ar" ? "rtl" : "ltr",
                            alignItems: "right",
                            height: "30px",
                             fontSize:"1em"
                          }}
                          onClick={async () => await handleLogOutUser()}
                        >

                          {t("Logout")}

                        </MenuItem>
                        <MenuItem
                          style={{
                            direction: router.locale == "ar" ? "rtl" : "ltr",
                            alignItems: "right",
                            height: "30px",
                            fontSize:"1em"
                          }}
                          onClick={() => router.push(constants.deleteAccountUrl)}
                        >

                          {t("deleteAccount")}

                        </MenuItem>
                      </Menu>
                    </div>
                    {/* end */}
                    <Searchmobile />
                    <>
                      {/* start langauge and country menu */}
                      <CustomeBtn2
                        aria-label="more"
                        id="long-button"
                        aria-controls={openLCC ? 'long-menu' : undefined}
                        aria-expanded={openLCC ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClickLCC}
                        className="language"
                        style={{ minWidth: "45px" }}
                      >

                        {vocabTerms.countriesService && vocabTerms.countriesService.filter(ele => ele.language.code == locale).length > 1 ?
                          Images.filter(
                            i => i.fileId == selectImag
                          ).length > 0
                            && Images.filter(
                              i =>
                                i.fileId == selectImag
                            )[0].image ?
                            <img

                              className={style.basketnav}
                              src={
                                Images.filter(
                                  i => i.fileId == selectImag
                                ).length > 0
                                  ? Images.filter(
                                    i =>
                                      i.fileId == selectImag
                                  )[0].image
                                  : ""
                              }
                              alt={"country"}

                            />
                            : <CustomeAvatar className={style.basketnav} alt="Country">{vocabTerms.countriesService.filter(ele => ele.code == getCookieValue("userCountry"))[0]
                              .field4} </CustomeAvatar>
                          : ""
                        }
                        <div className={style.FlexPM}>
                          {router.locale}
                          <ExpandMoreIcon />
                        </div>

                      </CustomeBtn2>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          'aria-labelledby': 'long-button',
                        }}

                        anchorEl={anchorLCC}
                        open={openLCC}
                        onClose={handleCloseLCC}
                        PaperProps={{
                          style: {

                            width: '28ch',
                            direction: router.locale == "ar" ? "rtl" : "ltr",
                            alignItems: "right",
                            padding: "0px 10px",
                            maxHeight: "100%",

                          },
                        }}
                      >

                        <FormControl>
                          <CustomeFormLabel id="Change-language" className={style.flex + " " + style.halfGap}> {t("change-lan")} </CustomeFormLabel>
                          <LanguageSwitcher />
                        </FormControl>
                        <hr className={style.marginZero} />
                        <FormControl>
                          <CustomeFormLabel id="Change-Currency" className={style.flex + " " + style.halfGap + " " + style.paddingDialogLCCT}> {t("change-currency")} </CustomeFormLabel>
                          <CustomeFormLabel className={style.flex + " " + style.halfGap + " " + style.paddingDialogLCCB + " " + style.paddingDialogLCCT}  >
                            {activeCurrency}
                            <CustomeBtn2
                              aria-label="more"
                              id="long-button"
                              aria-controls={openCurrency ? 'long-menu' : undefined}
                              aria-expanded={openCurrency ? 'true' : undefined}
                              aria-haspopup="true"
                              onClick={handleClickCurrency}
                            >
                              {t("Change")}
                            </CustomeBtn2></CustomeFormLabel>
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              'aria-labelledby': 'long-button',
                            }}

                            anchorEl={anchorCurrency}
                            open={openCurrency}
                            onClose={handleCloseCurrency}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',

                              },
                            }}
                          >

                            {currenciesRes.data ? currenciesRes.data.currencies.map((ele, index) => {
                              return <MenuItem
                                style={{
                                  direction: router.locale == "ar" ? "rtl" : "ltr",
                                  alignItems: "right"
                                }}
                                onClick={() => {
                                  setAnchorCurrency(null);

                                  // exchangeAllCurrencies.refetch()
                                  currenciesRes.refetch(ele.id)
                                  getExchangeAllCurrencies(ele.id)
                                  encryptLocalStorage("currency", ele.id)
                                  if (ele.transCurrency.filter(d => d.language.code == locale).length > 0) {
                                    setActiveCurrency(ele.transCurrency.filter(d => d.language.code == locale)[0].name)
                                  }
                                  else {
                                    setActiveCurrency(ele.transCurrency[0].name)
                                  }

                                  setActiveCurrencyCode(ele.code)
                                  setActiveCurrencyId(ele.id)
                                }}
                                key={index}
                              >
                                {ele.transCurrency.filter(d => d.language.code == locale).length > 0 ?
                                  <CustomeFormControl
                                    control={<Radio className={style.TealColor} id={`${ele.transCurrency[0].id}`}
                                      checked={ele.transCurrency.filter(d => d.language.code == locale)[0].name === activeCurrency} />
                                    }
                                    label={ele.transCurrency.filter(d => d.language.code == locale)[0].name}
                                  /> : <CustomeFormControl
                                    control={<Radio className={style.TealColor} id={`${ele.transCurrency[0].id}`}
                                      checked={ele.transCurrency[0].name === activeCurrency} />
                                    }
                                    label={ele.transCurrency[0].name}
                                  />}
                              </MenuItem>
                            }) : ""}





                          </Menu>
                        </FormControl>

                        <div className={style.paddingDialogLCCT}> </div>

                        {vocabTerms.countriesService && vocabTerms.countriesService.filter(ele => ele.language.code == locale).length > 1 ? <hr className={style.marginZero + " " + style.paddingDialogLCCT} /> : ""}
                        {vocabTerms.countriesService && vocabTerms.countriesService.filter(ele => ele.language.code == locale).length > 1 ?
                          <div className={style.flex + " " + style.GapCou}>
                            {Images.filter(
                              i => i.fileId == selectImag
                            ).length > 0
                              && Images.filter(
                                i =>
                                  i.fileId == selectImag
                              )[0].image ? <img

                                className={style.basketnav}
                                src={
                                  Images.filter(
                                    i => i.fileId == selectImag
                                  ).length > 0
                                    ? Images.filter(
                                      i =>
                                        i.fileId == selectImag
                                    )[0].image
                                    : ""
                                }
                                alt={"country"}

                              /> :
                              <CustomeAvatar className={style.basketnav} alt="Country">{vocabTerms.countriesService.filter(ele => ele.code == getCookieValue("userCountry"))[0]
                                .field4} </CustomeAvatar>
                            }


                            <CustomeBtn2
                              aria-label="more"
                              id="long-button"
                              aria-controls={openCountry ? 'long-menu' : undefined}
                              aria-expanded={openCountry ? 'true' : undefined}
                              aria-haspopup="true"
                              onClick={handleClickCountry}
                              style={{ textTransform: "capitalize" }}
                            >
                              {t("change-country")}
                            </CustomeBtn2>
                          </div> : ""}
                        {vocabTerms.countriesService && vocabTerms.countriesService.filter(ele => ele.language.code == locale).length > 1 ?
                          <CustomeMenu
                            id="long-menu"
                            locale={router.locale}
                            MenuListProps={{
                              'aria-labelledby': 'long-button',
                            }}

                            anchorEl={anchorCountry}
                            open={openCountry}
                            onClose={handleCloseCountry}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',

                              },
                            }}
                          >

                            {countriesList.map((option, index) => (

                              <MenuItem
                                style={{
                                  direction: router.locale == "ar" ? "rtl" : "ltr",
                                  alignItems: "right"
                                }}
                                key={index} onClick={() => saveDataOfCountry(option)}>
                                <ListItemIcon>
                                  {Images.filter(
                                    i => i.fileId == JSON.parse(option.field2).fileId
                                  ).length > 0
                                    && Images.filter(
                                      i =>
                                        i.fileId == JSON.parse(option.field2).fileId
                                    )[0].image ? <img
                                      className={style.selectCountry}
                                      src={
                                        Images.filter(
                                          i => i.fileId == JSON.parse(option.field2).fileId
                                        ).length > 0
                                          ? Images.filter(
                                            i =>
                                              i.fileId == JSON.parse(option.field2).fileId
                                          )[0].image
                                          : ""
                                      }
                                      alt={"country"}

                                    /> : <CustomeAvatar className={style.basketnav} alt="Country">{option.field4} </CustomeAvatar>}

                                </ListItemIcon>
                                {option.name}
                              </MenuItem>
                            ))}
                          </CustomeMenu>
                          : ""}
                           <div className={style.paddingDialogLCCT}> </div>
                                <hr className={style.marginZero + " " + style.paddingDialogLCCT} /> 
                                  {citiesLst&&citiesLst.length>0?  <CustomeBtn2
                                    aria-label="more"
                                    id="long-button-3"
                                    aria-controls={openCity ? 'long-button-3' : undefined}
                                    aria-expanded={openCity ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClickCity}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                   <div>{t("combo-city-label")} </div>
                                  </CustomeBtn2>:""}
                                  <Menu
                                  id="long-button-3"
                                  locale={router.locale}
                                  MenuListProps={{
                                    'aria-labelledby': 'long-button-3',
                                  }}

                                  anchorEl={anchorCity}
                                  open={openCity}
                                  onClose={handleCloseCity}
                                  PaperProps={{
                                    style: {
                                      maxHeight: ITEM_HEIGHT * 4.5,
                                      width: '20ch',

                                    },
                                  }}
                                >
                                 
                                  {citiesLst&&citiesLst.map((option, index) => (

                                    <MenuItem
                                      style={{
                                        direction: router.locale == "ar" ? "rtl" : "ltr",
                                        alignItems: "right"
                                      }}
                                      key={index} 
                                      onClick={() => saveDataOfCity(option)}
                                      selected={getCookieValue("foodCity").code == option.code||selectedCityValue==option.code}
                                    >

                                     
                                      {option.name}
                                    </MenuItem>
                                  ))}


                                </Menu>
                      </Menu>
                     
                      {/* End   */}

                    </>


                    {hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "basket").length > 0 ?
                      <div>

                        <span className={style.AtagIcon} onClick={() => router.push("/basket")} style={{ cursor: "pointer" }}>

                          <CustomeBadge locale={locale} badgeContent={ProductsCounter > 0 ? ProductsCounter : null}>

                            <div className={style.basketnav}>
                              <Image
                                className={style.basketnav}
                                src={`${config.basePath}/assest/navbasket.svg`}
                                alt={"hakeem-basket"}
                                width={50}
                                height={50}
                              />
                            </div>
                          </CustomeBadge>

                        </span>


                      </div>
                      : ""}


                    {hakeemServicesList && hakeemServicesList.length > 0 && hakeemServicesList.filter(i => i.code == "favorites").length > 0 ?
                      <div>

                        <span className={style.AtagIcon} onClick={() => router.push(constants.favoritesLink)}>
                          {" "}
                          <div className={style.basketnav}>
                            <Image
                              className={style.basketnav}
                              src={`${config.basePath}/assest/navfav.svg`}
                              alt={"hakeem-favorite"}
                              width={50}
                              height={50}
                            />
                          </div>
                        </span>

                      </div>
                      : ""}
                  </div>
                ) : (
                    ""
                  )}
                {mobile || tablet ? (
                  <span className={style.menuflex} onClick={handleToggleMenu}>
                    <div className={"menu"}>
                      <div className={menu ? "toggle menuLine1" : ""}></div>
                      <div className={menu ? "toggle menuLine2" : ""}></div>
                      <div className={menu ? "toggle menuLine3" : ""}></div>
                    </div>
                  </span>
                ) : (
                    ""
                  )}
                <Timer
                  newTime={0}
                  estimatedTime={300}
                  onTick={setCounter2}
                  active={activeAcounter}
                  timerReset={timerReset}
                  TimerCallBack={() => setIsShowLogin(false)}
                />
                {DialogData.open ? <ConfirmDialog
                  open={DialogData.open}
                  text={DialogData.Text}
                  type={"Erorr"}
                  btnOk={() => {
                    setTimeout(() => {
                      window.location =
                        config.basePath + "/user-ms/login" + "?next=" + router.asPath + "#Medicine";
                    }, 1000);

                  }}
                  btnCancel={() => {
                    setDialogData({
                      open: false

                    })
                  }}
                  confirmBtnTitle={t("btnOk")}
                /> : ""}
                {openCitiesDialog ? <OrderMealsCitiesDialog
                  open={openCitiesDialog}
                  text={DialogData.Text}
                  type={"Erorr"}
                  btnCancel={() => {
                    setOpenCitiesDialog(false)
                  }}
                  callBack={(data) => {
                    setOpenCitiesDialog(false)
                    let tempValue=''
                    entityCategoriesFoodLst&& entityCategoriesFoodLst.map(ele=>{
                      if(entityCategoriesFoodLst.length>1){
                        tempValue += ele.code+ ","
                      }
                      else{
                        tempValue += ele.code 
                      }
                     
                    })
                    router.push({ pathname: "/profile", query: {entityType:tempValue, city: data ,isFood:true} })
                  }}
                  confirmBtnTitle={t("btnOk")}
                /> : ""}
              </nav>
            </div>
            <div className={style.MenuMar}>

              <HoverCategory />
            </div>
            <>
              {children}
            </>
            <>
              {showDownloadAppSection && <DownloadApp />}
              <SquareFooter />
              <Footer />
            </>
          </ServiceContext.Provider>
        </CurrencyContext.Provider>
      </SystemConfigrationContext.Provider>
    </>
  );
};
