import styled from '@mui/system/styled';
// import { themeGet } from '@styled-system/theme-get';
import FormControlLabel from '@mui/material/FormControlLabel';



export const MenuItem = styled('button')({
  '@media (max-width:768px)': {
    fontSize: "1.1rem",
  },
  display: "block",
  textDecoration: "none",
  // color:" #F52A59",
  color: "black",
  fontSize: "0.8rem",
  fontWeight: 600,
  transition: " 0.5s ease",
  transform: "translateX(0%)",
  outline: 0,
  cursor: "pointer",
  backgroundColor: "white",
  border: "none",


  '@media (min-width:1241px)': {
    fontSize: "1.1rem",
    display: "block",
    textDecoration: "none",
    // color:" #F52A59",
    color: "black",
    fontWeight: 600,
    transition: " 0.5s ease",
    transform: "translateX(0%)",
    outline: 0,
    cursor: "pointer",
    backgroundColor: "white",
    border: "none",
  }
})

export const CustomeFormControl = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    fontSize: "0.8em"
  },
  '& .MuiSvgIcon-root': {
    fontSize: "0.8em"
  }



})



