import styled from '@mui/system/styled';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Box,TextField,Button ,
    FormControl , 
    Select, FormControlLabel
  } from "@mui/material";
  import LoadingButton from '@mui/lab/LoadingButton';
  import Rating from '@mui/material/Rating'
  export const CustomeBox =styled(Box)(({theme,locale})=>({
    top:-142,
    padding: "20px 0px",

    position: "absolute",
    '@media (min-width:280px) and (max-width:319px)': {
      top:-104,
    },
    '@media (min-width:320px) and (max-width:321px)': {
     top:-116
    },
    '@media (min-width:768px) and (max-width:912px)': {
      top:-190
    }
    ,
    '@media (min-width:913px)': {top:-190}
  
  }))
  export const CustomeDialogContent = styled(DialogContent)(({ theme, locale }) => ({
    padding: theme.spacing(0),
    margin: "5px auto",
    overflow: "hidden",
  
    position:"relative",
    left:0,
    right:0,
    width:"100%",
    top:10
  
  
  
  }))
  export const CustomeDialogContent2=styled(DialogContent)(({theme,locale})=>({
    paddingBottom:0,
    paddingLeft:24,
    paddingRight:24,
    '@media only screen and  (min-width:280px){': {
      paddingLeft:8,
      paddingRight:8,
    },
    '@media (min-width:768px) and (max-width:912px)': {
      paddingLeft:24,
      paddingRight:24,
    },
    '@media (min-width:913px) ': {
      paddingLeft:24,
      paddingRight:24,
    }
  }))
  export const BootstrapDialog = styled(Dialog)(({ theme }) => ({

    '& .MuiDialog-paper': {
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
      overflow: "visible",
      zIndex: 1,
      direction: "ltr"
  
 
      ,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: "white",
  
  
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: "white",
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: "white",
      },
  
      // '& .MuiSelect-icon': {
      //   color: "#008C8D "
      // },
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        borderColor: "white"
      },
      '& .MuiInputLabel-root': {
        // fontSize: "1.2rem",
        // color:"#546e7a"
      }
  
    }
  
  }))
  export const Customebtn = styled(LoadingButton)({
    // color: "white",
    '&.Mui-disabled': {
      color: "grey !important",
      border: "none !important",
      backgroundColor: "#bcbcbc70 !important",
  
    },
    '&:hover': {
  
      border: "none"
    },
  
  
  
  
    // backgroundColor: " #008C8D  !important ",
    fontSize: "1rem ",
    // width: "30%",
    border: "none",
    height: "1.0375em",
    boxSizing: "content-box",
    padding: " 12.5px 14px",
    borderRadius: '12px',
    margin: "auto",
    display: "flex",
    '@media (min-width:280px) and (max-width:300px)': {
      // width: "30% !important",
    },
    '@media (min-width:768px) and (max-width:912px)': {
  
      // backgroundColor: " #008C8D  ",
      fontSize: "1rem ",
      // width: "30% ",
      height: "1.0375em",
      boxSizing: "content-box",
      padding: " 12.5px 14px",
      borderRadius: '12px',
      border: "none",
      margin: "auto",
      display: "flex"
  
    },
  
    '@media (min-width:913px) ': {
  
      // backgroundColor: " #008C8D  ",
      fontSize: "1rem",
      // width: "30% ",
      height: "1.0375em",
      boxSizing: "content-box",
      padding: " 12.5px 14px",
      borderRadius: '12px',
      border: "none",
      margin: "auto",
      display: "flex"
  
    },
  
  });
  export const CustomeFormControl = styled(FormControl)({
    // '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall':{
    //  padding: "10px !important",
 
    // },
     '& .MuiInputLabel-root': {
             color: "#008C8D ",
            //  fontSize: "1rem !important",
             fontWeight: 600,
        
     },
    
     
     '& .MuiOutlinedInput-input':{
        
         boxShadow: 'none',
        },
        
         width: "100%",
         margin: "auto",
        //  padding:"10px 0px",
  
         "& .MuiOutlinedInput-root": {
             borderRadius: "12px",
             boxShadow: "0 0 4px #bcbcbc !important",
             },
         '& .MuiSvgIcon-root': {
             fontSize: "1.5rem "
         },

  
     '@media (min-width:768px) and (max-width:912px)': {
         '& .MuiInputLabel-root': {
             color: "#008C8D ",
            //  fontSize: "1.2rem",
             fontWeight: 600
         },
       
             width: "100%",
             margin: "auto"
       
     },
     '@media (min-width:913px)': {
         '& .MuiInputLabel-root': {
             color: "#008C8D ",
            //  fontSize: "1.2rem",
             fontWeight: 600
         },
             width: "100%",
             margin: "auto",
       
             '& .MuiSvgIcon-root': {
                 fontSize: "1.5rem !important"
             }
     }
 })
 export const CustomeFormControl2 = styled(FormControl)(({ theme, locale }) => ({
    width: "95%",
    margin: 0,
  
    margin: "10px auto",
    '& label.Mui-focused': {
      color: "#008C8D ",
    },
  
    '& .MuiSelect-icon': {
      color: "#008C8D "
    },
    '& .MuiInputLabel-root': {
  
      fontSize: "1rem !important",
  
    },
    '@media only screen and  (min-width:280px){': {
      width: "99%",
      '& .MuiInputLabel-root': {
  
        fontSize: "1rem !important",
  
      },
    },
    '@media (min-width:768px) and (max-width:912px)': {
      '& .MuiInputLabel-root': {
  
        fontSize: "1.2rem !important",
  
      },
      width: "100%",
    },
    '@media (min-width:913px) ': {
      '& .MuiInputLabel-root': {
  
        fontSize: "1.2rem !important",
  
      },
      width: "100%",
    },
  
  }));
 export const CustomeDialogActions = styled(DialogActions)(({ theme, locale }) => ({


    gap: "1rem !important",
    '&>:not(:first-of-type)':{
      marginRight:"auto !important",
      marginLeft:"auto !important"
        }
  
  
  }))
  export const Customeselect = styled(Select)(({ theme, locale }) => ({

    '& .MuiSelect-select': {
      textAlign: locale == "ar" ? "right" : "left"
    },
    '& .MuiSelect-root': {
      borderRadius: "8px",
    },
  }));
  export const CutomeIcon = styled("div")(({ theme }) => ({
    marginLeft: "auto",
    marginRight: "auto",
    position: "absolute",
    top: 50,
    left: 100,
    "@media (min-width:280px) and (max-width:300px)": {
      left: 50
    },
    "@media (min-width:768px)": {
      left: 0
    }
  }));
  export const CutomeDivRoot = styled("div")(({ theme }) => ({
    zIndex: 10,
    top: 0,
    left: 0,
  
    backgroundColor: "#fff",
    height: "100%",
    position: "fixed",
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      textAlign: "revert"
    },
    "& :-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px #efedf0  inset",
      border: "unset !important",
      borderRadius: "none !important"
    },
    "& :-internal-autofill-selected": {
      backgroundColor: "#efedf0 !important",
      appearance: "none ",
      border: "unset ",
      borderRadius: "unset "
    }
  }));
  export const CustomeTextField = styled(TextField)(({ theme, locale }) => ({
    padding: "0px !important",
  
    borderRadius: "12px",
    boxShadow: "0 0 4px #bcbcbc !important",
    '& .MuiSvgIcon-root': {
      color: '#008C8D ',
  },
    "& label.Mui-focused": {
      color: "#008C8D "
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white"
    },
  
    "& .MuiInput-underline:after": {
      borderButtomColor: "#008C8D "
    },
  
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
  
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
  
    width: "259px",
  
    "& .MuiButton-root:hover": {
      border: "none"
    },
    "& .MuiInputLabel-root": {
      color: "#bcbcbc"
    },
  
    "@media (min-width:600px)": {
      width: "350px"
    }
  }));
  export const CustomeTextField2 = styled(TextField)(({ theme, locale }) => ({
    padding: "0px !important",
    textAlign:"center !important",
     fontSize:"16px !important",
    borderRadius: "12px",
    boxShadow: " 3px 2px 6px #bcbcbc !important",
    '& .MuiSvgIcon-root': {
      color: '#008C8D ',
    },
    "& label.Mui-focused": {
      color: "#008C8D "
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white"
    },
  
    "& .MuiInput-underline:after": {
      borderButtomColor: "#008C8D "
    },
  
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
  
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
  
    width: "400px",
  
    "& .MuiButton-root:hover": {
      border: "none"
    },
    "& .MuiInputLabel-root": {
      color: "#bcbcbc"
    },
    "& .muirtl-1pemhpb-MuiInputBase-input-MuiOutlinedInput-input":{
      textAlign:"center !important",
      fontSize:"16px !important",
    },
  
    "@media (min-width:600px)": {
      width: "400px"
    }
  }));
  export const CustomeDialogActions2 = styled(DialogActions)(({ theme, locale }) => ({
    flexDirection: "column",
    gap:"0.5rem"
}))
  export const CustomeFormControlLabel = styled(FormControlLabel)({

    '& .MuiTypography-root': {
        fontSize: "1rem ",
        fontWeight: 600
    },
    '& .Mui-checked': {
        color: "#008C8D  !important"
    },


    width: "100% ",
    margin: "auto  ",
    display: "flex",
    alignItems: " center",

    '@media (min-width:768px) and (max-width:912px)': {

        width: "97% !important",
        margin: "auto",
        display: "flex",
        alignItems: " center",

        '& .MuiTypography-root': {
            fontSize: "1.2rem ",
            fontWeight: 600
        },



    },
    '@media (min-width:913px)': {
        '&.MuiTypography-root': {
            fontSize: "1.2rem ",
            fontWeight: 600
        },

        width: "97% !important",
        margin: "auto !important",
        display: "flex",
        alignItems: " center",





    }
})
export const CustomeRating = styled(Rating)({
    justifyContent: "space-around",
})
export const CustomeForm = styled(TextField)(({ theme, locale }) => ({
    padding: "0px !important",
    "& .MuiOutlinedInput-root": {
        borderRadius: "12px",
        boxShadow: "0 0 4px #bcbcbc !important",
    },
    '& .MuiSvgIcon-root': {
        color: '#008C8D ',
    },
    "& label.Mui-focused": {
        color: "#008C8D "
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "white"
    },

    "& .MuiInput-underline:after": {
        borderButtomColor: "#008C8D "
    },

    "& .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },

    "& .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },

    width: "100%",

    "& .MuiButton-root:hover": {
        border: "none"
    },
    "& .MuiInputLabel-root": {
        color: "#bcbcbc"
    },

    "@media (min-width:600px)": {
        width: "100%"
    }
}));
export const CustomeTextField3 = styled(TextField)(({ theme, locale }) => ({
  padding: "0px !important",

  borderRadius: "12px",
  boxShadow: "0 0 4px #bcbcbc !important",
  '& .MuiSvgIcon-root': {
    color: '#008C8D ',
  },
  "& label.Mui-focused": {
    color: "#008C8D "
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "white"
  },

  "& .MuiInput-underline:after": {
    borderButtomColor: "#008C8D "
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none"
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none"
  },

  width: "259px",

  "& .MuiButton-root:hover": {
    border: "none"
  },
  "& .MuiInputLabel-root": {
    color: "#bcbcbc"
  },

  "@media (min-width:600px)": {
    width: "85%"
  }
}));
export const CustomeFormControl3 = styled(FormControl)({
  alignItems: "center !important",
  '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall':{
   padding: "10px !important",

  },
   '& .MuiInputLabel-root': {
           color: "#008C8D ",
           fontSize: "1rem !important",
           fontWeight: 600,
      
   },
  

   '& .MuiOutlinedInput-input':{
      
       boxShadow: 'none',
      },
      
       width: "100%",
       margin: "auto",
       padding:"10px 0px",

       "& .MuiOutlinedInput-root": {
           borderRadius: "12px",
           boxShadow: "0 0 4px #bcbcbc !important",
           },
       '& .MuiSvgIcon-root': {
           fontSize: "1.5rem !important"
       },

   '@media (min-width:768px) and (max-width:912px)': {
       '& .MuiInputLabel-root': {
           color: "#008C8D ",
           fontSize: "1.2rem",
           fontWeight: 600
       },
     
           width: "100%",
           margin: "auto"
     
   },
   '@media (min-width:913px)': {
       '& .MuiInputLabel-root': {
           color: "#008C8D ",
           fontSize: "1.2rem",
           fontWeight: 600
       },
           width: "100%",
           margin: "auto",
     
           '& .MuiSvgIcon-root': {
               fontSize: "1.5rem !important"
           }
   }
})