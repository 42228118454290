import axios from "axios";
import Router, { useRouter } from "next/router";
import * as config from "../../next.config.js";
import { deleteCookie, getCookieValue, getAllCookies, printLogs } from "./helpers";
import Cookie from "js-cookie";
import aesjs from "aes-js";
// import utf8 from 'utf8'
import { constants } from '../../constants'
import { encryptLocalStorage } from '../../components/helpers/helpers'
// import { decryptLocalStorage } from "../../components/helpers/helpers";
// import * as config from '../next.config'
import { getImage } from './images'
export const loginUser = async (username, password) => {
  const data = await axios.post(`${config["basePath"]}/api/user/login`, {
    data: { username: username, password: password },
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": Cookie.get("locale") || Router.locale
    }
  });
  return data;
};

export const validatePasswordPolicy = async (userTypeId, enable) => {
  const data = await axios.post(`${config["basePath"]}/api/user/validatePasswordPolicy`, {

    data: {
      fields: {

      },
      userTypeId: userTypeId,
      enable: enable

    },
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": Cookie.get("locale") || Router.locale

    }
  });

  return data;
};
export const forgetPassword = async (username, note) => {
  const data = await axios.post(`${config["basePath"]}/api/user/forgetPassword`, {

    data: { userName: username, note: note },


    headers: {
      "Content-Type": "application/json",
      "Accept-Language": Cookie.get("locale") || Router.locale

    }
  });

  return data;
};
export const ResetPasswordOtp = async (code) => {
  const data = await axios.post(`${config["basePath"]}/api/user/resetPassword`, {

    data: { otp: code },
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": Cookie.get("locale") || Router.locale

    }
  });

  return data;
};

export const ForceChangePassword = async (username, pass, newPass) => {
  const data = await axios.post(`${config["basePath"]}/api/user/forceChangePassword`, {

    data: { username: username, password: pass, newPassword: newPass },
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": Cookie.get("locale") || Router.locale

    }
  });

  return data;
};
export const logOutUser = async () => {
  const data = await axios.post(`${config["basePath"]}/api/user/logout`, {
    // data: { username: username, password: password },
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": Cookie.get("locale") || Router.locale
    }
  });
  return data;
};

export const encryption = text => {
  const key = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
  const iv = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36]
  var textBytes = aesjs.utils.utf8.toBytes(text);
  var aesCtr = new aesjs.ModeOfOperation.ctr(key, iv);
  var encryptedBytes = aesCtr.encrypt(textBytes);
  var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);

  return encryptedHex
};
export function setLoginCookies(res) {
  try {
   
    Cookie.set(encryption("login"), encryption(JSON.stringify({ Authorization: `Bearer ${res.data["data"]["token"]}`, "userID": res.data.data.userID, userName: res.data.data.userName, entityDetailsId: res.data.data.entityDetailsId, userTypeId: res.data.data.userTypeId,officialName:res.officialName.replace(/[^\w\u0621-\u064A\s]/gi, ' '),image:res.image})), { path: config.basePath!="" ? config.basePath : "/" });

    Cookie.set(encryption("fullName"), encryption(JSON.stringify(res.data.data.firstName + " " + res.data.data.lastName)), { path: config.basePath!="" ? config.basePath : "/" });
    // Cookie.set(encryption("officialName"), encryption(JSON.stringify(res.officialName)), { path: config.basePath ? config.basePath : "/" }); 
    // Cookie.set(encryption("UserImage"), encryption(JSON.stringify(res.image)), { path: config.basePath ? config.basePath : "/" }); 
    encryptLocalStorage("fullName", res.data.data.firstName + " " + res.data.data.lastName)
  } catch (err) {
    return (err);
  }
}
export function handleLogout() {

  localStorage.removeItem(encryption(constants.storedData));

  deleteCookie(encryption("login"), false);
  deleteCookie(encryption("fullName"), false);

}

export function isLoggedIn() {
  let token = getCookieValue("login");

  if (token) {
    const request = axios({
      method: "POST",
      url: `${config["basePath"]}/api/user/checkToken`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token.Authorization
      }
    }).then(response => {

      if (parseInt(response.data.data.id) === 0) {

        // handleLogout();
        // if (Router.router.route != "/"){
        //   Router.push("/");
        // }
      }
    });
    return true;
  } else {
    return false;
  }
}
export function checkTocken() {
  // let token = getCookieValue("login");

  // if (token) {
  const request = axios({
    method: "POST",
    url: `${config["basePath"]}/api/user/checkToken`,
    headers: {
      "Content-Type": "application/json",
      // Authorization: token.Authorization
    }
  });
  return request
  // return true;
  // } else {
  //   return false;
  // }
}
export const getDataUser = async (langId, token) => {
  let dataUser={officialName: null,image:null}
  return  await axios.get(`${config['basePath']}/api/registeration/transEntityDetails/get`,
    {
      params: {
        langId: langId,
        Authorization: JSON.stringify(`Bearer ${token}`)
      }
    })
    .then(async (entity) => {
      if (entity.data.data.data.length > 0) {
        let tempData = entity.data.data.data
        // console.log("tempData==========>",tempData)
        dataUser.officialName=entity.data.data.data[0].officialName.replace(/[^\w\u0621-\u064A\s]/gi, ' ')

    return await axios.get(`${config['basePath']}/${constants.ENTITY_IMAGES_URL}`,
          {
            params: {
              entityId: JSON.stringify(tempData.map(ele => ele.entityDetailsId)),
           
              entityImageType: 'logo',
              url: `/api/registrations/v1/entityImages`,
            }
          })
          .then(async (entitiesImgs) => {
            let tempImagesData = entitiesImgs.data.data.data
            if (tempImagesData.length > 0) {
              if (tempImagesData[0].files) {
             
                dataUser.image= tempImagesData[0].files
              }
        
            }
            return  await dataUser;
          }).catch(err => err)
      }
    }).catch(err => err)
};
