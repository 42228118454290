import React, { useCallback } from 'react'
import style from '../../../styles/mystyle.module.css'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { Box } from '@mui/material/'
import { useTranslation } from 'next-i18next'
import * as config from '../../../next.config.js'
import FormSearch from '../FormSearch'
import  {BootstrapDialog } from "../StyledComponent/Dialog.style"
const Searchmobile = (props) => {
  const { children, onClose, ...other } = props;

  return (


    <DialogTitle sx={{ m: 0, p: 2, display: "flex", justifyContent: "space-between", }} {...other} className={style.DialogTitle}>
      {children}
      {onClose ? (<IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: "90%",
          left: "80%",
          top: 23,
          marginBottom: 0,
          color: "#008C8D ",
          '@media (min-width:600px)': {
            left: "90%",
          },
        }}>
        <CloseIcon />
      </IconButton>
      ) : null}
    </DialogTitle>

  )

}

Searchmobile.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

export default function CustomizedDialogs() {
  const { t } = useTranslation("common")
  const [open, setOpen] = React.useState(false);
  const [set, setSet] = React.useState('')
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const onClose = useCallback((callback) => {
    setOpen(callback)
  },
    [],
  )

  return (
    <div>
      {/* <div className={style.Container}> */}
      <Box className={style.searchboxcontainer} sx={{
        maxWidth: {
          xs: 300,
          sm: 125,
          lg: 145,
          xl: 200
        },
        // marginTop: "-2%",
        zIndex: 1,
        position: "relative",
      }}>


        <div className={style.basketnav + " " + style.blueSearchBg}
          onClick={handleClickOpen}
        >
          {/* <SearchIcon   
          alt={"hakeem"}
            style={{
              color: "white",
              width:"1rem",
              height:"1rem"

            }} /> */}
          <img src={`${config.basePath}/assest/search.svg`} alt={"hakeem"}
            style={{
              color: "white",
              width: "0.9rem",
              height: "0.9rem"

            }} />
        </div>
        {/* <img className={style.basketnav} onClick={handleClickOpen} src={`${config.basePath}/assest/search-pink.svg`} alt={"hakeem"} /> */}

        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}>
          <Searchmobile id="customized-dialog-title" onClose={handleClose}>
            <div style={{ marginBottom: 0, paddingLeft: "0px", paddingRight: "0px", fontWeight: 600, color: "#008C8D ", fontSize: "1.5rem" }}>{t("Hakeem")} </div>
          </Searchmobile>

          <DialogContent dividers>
            <FormSearch onClose={onClose} />

          </DialogContent>

        </BootstrapDialog>


        {/*      </div> */}
      </Box>
      {/* </div> */}

    </div>
  )
}