import React from "react";
// import "./styles.css";
import { getCookieValue, printLogs } from "../../helpers/helpers";
// import { handleLogout } from "../../helper/auth";
import Cookie from "js-cookie";
import { useRouter } from "next/router";
// import SessionExpiredDialog from "../Login/SessionExpiredDialog";
// import { handleLogout } from "../../helper/auth";
import { handleLogout,logOutUser ,checkTocken} from "../../helpers/auth";
// import { checkTocken, logOutUser } from "../../helper/auth";
import { encryption } from "../../helpers/helpers";
import ConfirmDialog from '../Dialogs/ConfirmDialog'
import { useTranslation } from "next-i18next";

export default function Timer({
  estimatedTime,
  onTick,
  active,
  newTime,
  timerReset,
  TimerCallBack
},props) {
  const { t } = useTranslation("common");
  const [counter, setCounter] = React.useState(estimatedTime);
  const [counterSecond, setCounterSecond] = React.useState(newTime);
  const [alertMessage, setAlertMessage] = React.useState({
    text: "  انتهت الجلسة يرجى اعادة تسجيل الدخول ",
    show: false
  });
  const router=useRouter()
  React.useEffect(() => {
    let timer;
    let timersecond;
    if (active) {
     
      timersecond = setTimeout(() => setCounterSecond(counterSecond + 1), 1000);
      timer = counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      onTick(counterSecond);
    }
    if(counter==0){

        if (getCookieValue("login")!=false && getCookieValue("login").Authorization) {
        // setAlertMessage({ text: alertMessage.text, show: true });
        // handleLogOutUser()
     
        }
    }
    return () => {
      if (timer && timersecond) {
        clearTimeout(timer);
        clearTimeout(timersecond);
      }
    };
  }, [counter, counterSecond, active, onTick]);

  React.useEffect(() => {
    if (timerReset) {
      setCounterSecond(newTime);
      setCounter(estimatedTime);
    }
   
  }, [timerReset, newTime, estimatedTime]);
  const handleLogin=()=>{
    // setLogin(true)
    setAlertMessage({ text: alertMessage.text, show: false });
    router.push("/user-ms/login")
    Cookie.set(encryption("showLogin"),encryption(true))
  }
  const handleLogOutUser = () => {
    checkTocken().then(res=>{
      if(res.data.data.id==0){
        handleLogout()

      }
      else{
        logOutUser().then(res => {
          
          if (res.data.data.successful) {
           
            handleLogout();
  

  
          }
          else{
            handleLogout();


         
          }
        })
        .catch(err => ( err));
      }
    }).catch(err=>{
      (err)
    })
   
  };
  React.useEffect(()=>{
 if(getCookieValue("login")&&getCookieValue("login").Authorization){


    checkTocken().then(res=>{
      if(res.data.data.id==0){
        setAlertMessage({ text: alertMessage.text, show: true });
        handleLogout()
  
      }
    }).catch(err=>{
      printLogs("err=======>",err)
    })
  }

  },[])
  return (
    <div>
          {/* <SessionExpiredDialog
        text={alertMessage.text}
        open={alertMessage.show}
        login={handleLogin}
   
      /> */}
      {alertMessage.show&&<ConfirmDialog
           text={alertMessage.text}
           open={alertMessage.show}
           btnOk={handleLogin}
           btnCancel={()=>{
            TimerCallBack(true)
            handleLogout()
            alertMessage.show=false
            setAlertMessage({...alertMessage})
            router.push("/user-ms/login")
           }}
           confirmBtnTitle={t("btnOk")}
      />}
      {/* <div>Time: {counterSecond} </div>
      <div>Countdown: {counter}</div> */}
      {props.children}
    </div>
  );
}
