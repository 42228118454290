import React, { useCallback } from 'react'
import style from '../../../styles/mystyle.module.css'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
// import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/material/'
import { useTranslation } from 'next-i18next'
import * as config from '../../../next.config.js'
import FormSearch from '../FormSearch'
import SearchIcon from '@mui/icons-material/Search'
import  {BootstrapDialog } from "../StyledComponent/Dialog.style"
const Searchbarhomepage = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, display: "flex", justifyContent: "space-between", }} {...other} className={style.DialogTitle}>
      {children}
      {onClose ? (<IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: "88%",
          left: "88%",
          top: 23,
          marginBottom: 0,
          color: "#008C8D ",
          '@media (min-width:600px)': {
            left: "88%",
          },
        }}>
        <CloseIcon />
      </IconButton>
      ) : null}
    </DialogTitle>

  )

}

Searchbarhomepage.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

export default function CustomizedDialogs() {
  const { t } = useTranslation("common")
  const [open, setOpen] = React.useState(false);
  const [set, setSet] = React.useState('')
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const onClose = useCallback((callback) => {
    setOpen(callback)
  },
    [],
  )

  return (
    <div style={{width:"35%"}}>
      {/* <div className={style.Container}> */}
      <Box className={style.searchboxcontainer} sx={{
        maxWidth: {
          xs: 300,
          sm: 200,
          lg: 200,
          xl: 200
        },
        // marginTop: "-2%",
        zIndex: 1,
        position: "relative",
      }}>


        <form className={style.searchboxhome}>
          <input type="text" className={style.searchhome} placeholder={t("placeholder")} onClick={handleClickOpen} />
          <button className={style.btnsearchhome} onClick={handleClickOpen} type={"button"}>
        
            <div  className={style.blueSearchBg + " " + style.imgsearchhome}
            >
         
              <img src={`${config.basePath}/assest/search.svg`} alt={"hakeem"}
                style={{
                  color: "white",
                  width: "0.9rem",
                  height: "0.9rem"

                }} />
            </div>
          </button>
          {open?<BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}>
            <Searchbarhomepage id="customized-dialog-title" onClose={handleClose}>
              <div style={{ marginBottom: 0, paddingLeft: "5px", paddingRight: "5px", fontWeight: 600, color: "#008C8D ", fontSize: "2rem" }}>{t("Hakeem")} </div>
            </Searchbarhomepage>

            <DialogContent dividers>
              <FormSearch onClose={onClose} />

            </DialogContent>

          </BootstrapDialog>:""}

        </form>
   
      </Box>
 

    </div>
  )
}