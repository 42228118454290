
import config from '../../../../next.config.js';
import axios from 'axios'
// import getIncludeStatusIds from '../getIncludeStatusIds'

export async function getTermsByVocabCode(VocabCode) {
  
    let reqData = {};
    reqData.pageId=1
    if (VocabCode!=null) {
      reqData.vocabCode = [VocabCode];
    }
  
  
    const request = await axios.post(
      `${config["basePath"]}/api/globals/terms/getTermsByVocabCode`,
      {
        headers: {
          "Content-Type": "application/json"
        },
        data: reqData
      }
    );
    if (Array.isArray(request.data.data)) {
      const dataString = JSON.stringify(request.data.data);
   
      return dataString;
    }
  }

  export async function getTermsByVocabCodes(VocabCode) {
  
    let reqData = {};
    reqData.pageId=1
    if (VocabCode!=null) {
      reqData.vocabCode = VocabCode;
    }
    // let statusLst=await getIncludeStatusIds()
    const request = await axios.post(
      `${config["basePath"]}/api/globals/terms/getTermsByVocabCode`,
      {
        headers: {
          "Content-Type": "application/json"
        },
        data: reqData
      }
    );
 
    // console.log("statusLst===========>",request.data.data)
    if (Array.isArray(request.data.data)) {
      const dataString = JSON.stringify((request.data.data));
      return dataString;
    }
  }
export function filterApprovedTermsFromList(list) {

    return list.filter(item => !JSON.parse(config.env.notApprovedStatus).makerOnlyStatus.includes(item.statusId))
}

 getTermsByVocabCode