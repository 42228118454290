import* as config from "../../next.config.js";
import axios from "axios";
import {getCookieValue} from './helpers'
// import * as config from '../../next.config'
// export async function getImage(id, imgPath) {
  
//   ///----- response Local URL

//   //if(id != undefined && id != "" && imgPath != undefined && imgPath != "")
//   if(id != undefined && id != "")
//   {
//     let imgResponse = await axios.post(`${config["basePath"]}/api/Images/showImagesNew`, {            
//       data:{ 
//         path:imgPath,             
//         id:id,                
//         token: getCookieValue("login")?getCookieValue("login").Authorization:""         
//       },    
        
//       headers: {                
//         "Content-Type": "application/json",  
//       }    
//     })
  

//     if(imgResponse != undefined)
//     {
//       const url = imgResponse.data.data;  
//       return url

//     }
    
//   }
 
//   return null;
  
// };
const postImages=(file,name)=>{
  const body =new FormData()
  let myFile=new File([file],name,{type:file.type})
  body.append("file",myFile)
  fetch(config.basePath+"/api/Files/postToPublic",{method:"POST",body}).then(res=>{

  })

}
export async function getImage(id, imgPath) {
  
  return await axios.post(`${config["basePath"]}/api/Images/showImages`, {
                    data: {
                      path: imgPath,
                      token: " "
                    },
                    headers: {
                      "Content-Type": "application/json",
                      "content-encoding":"gzip"
                    }
                  })
                  .then(async res => {
                    // console.log("res=======>",res.data.data.data)
                    if(res.data.data&&res.data.data.data){
                    let base64= `data:${res.data.data.type};base64,${res.data.data.data}`;
                    let tempRes=await fetch(base64)
                    let blobRes=await tempRes.blob() 
                    // postImages(blobRes,id)
        
                    // return blobRes
                    return   URL.createObjectURL(blobRes)
                  }
                  else{
                    return ''
                  }
                  }).catch(err=>{
                    // console.log("mm==============>",err)
                  })

}
export async function oldGetImage(imgPath) {

  ///----- response Blob URL
  let finalPath = '';


  try
  {
      let imgResponse = await axios.post(`${config["basePath"]}/api/Images/showImages`, {
          data:{ path:imgPath,
            token: getCookieValue("login")?getCookieValue("login").Authorization:""
          },
        headers: {
          "Content-Type": "application/json",  
        }
      })

        try 
        {        
          const base64Data2  = imgResponse.data.data;
          const indexBase64String = base64Data2.indexOf('base64,')
          const base64 = base64Data2.substring(indexBase64String + 7)
          const indexType = base64Data2.indexOf('data:')
          const type = base64Data2.substring(indexType + 5, indexBase64String)
             
       

          const chunkLength = 512;
          const contentType = type;
  
          var byteCharArray = Array.from(atob(base64));
          
          var chunksIterator = new  Array(Math.ceil(byteCharArray.length / chunkLength));
          
          const bytesArrays =[];
          
          for (let c = 0; c < chunksIterator.length; c++){
            bytesArrays.push(new Uint8Array(byteCharArray.slice(c * chunkLength, chunkLength * (c + 1)).map(s=>s.charCodeAt(0))));
          }
  
          const blob = new Blob(bytesArrays, {type:contentType})
          let url = URL.createObjectURL(blob);
          finalPath = url;


          return  url;
      } 
      catch(err){ console.log('err=>', err )}
      
    

    finalPath =  imgResponse;

    let finalPath2 = finalPath.toString();
   
    
    
    return  finalPath;
  }
  catch(errr){
    //  console.log('urlPath===>1', errr )
    }

return  finalPath;

};

export async function getImageFromPublic(id) {
  return await axios.post(`${config["basePath"]}/api/Files/getFilesFromPublic`, {
                    data: {},
                    headers: {
                      "Content-Type": "application/json"
                    }
                  })
                  .then(async res => {
                    return res.data.data
              
                  }).catch(err=>{
                    console.log(err)
                  })

}
