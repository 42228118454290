import React, { useEffect, useState,useContext  } from 'react';
import {
  Dialog,
  DialogActions
} from "@mui/material";
import PropTypes from 'prop-types'
import { useTranslation } from 'next-i18next'
import { Customebtn } from "../StyledComponent/HintDialog1.style"
import { CustomeDialogContent2 } from "../StyledComponent/HintDialog1.style"
import FormControl from "@mui/material/FormControl";
import { getTermsByVocabCodes } from "../../helpers/global/term/term";
import { useRouter } from 'next/router'
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { styled } from '@mui/system'
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import MenuItem from "@mui/material/MenuItem";
import TermsContext from "../../../context/Trems"
import { getCookieValue,setCookiesValues,buidlObject} from '../../helpers/helpers'
const Customeselect = styled(Select)(({ locale }) => ({
  "& .MuiSelect-select": {
    textAlign: locale == "ar" ? "left" : "left",

  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: "#b5b5b5",
    borderWidth: "1px",
    border: "1px solid #b5b5b5 !important"
  },
  "& MuiSelect-select.Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#b5b5b5",
      borderWidth: "2px",
      border: "1px solid #b5b5b5 !important"
    }
  },


}));
const CustomeFormControl = styled(FormControl)(({ type2 }) => ({
  "& .Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#b5b5b5",
      borderWidth: "2px",
      border: "1px solid #b5b5b5 !important"
    }
  },
  "& :hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#b5b5b5",
      borderWidth: "2px",
      border: "1px solid #b5b5b5 !important"
    }
  },
  width: "100%",
  margin: 0,
  "& label.Mui-focused": {
    color: "#008C8D "
  },

  "& .MuiSelect-icon": {
    color: "#008C8D ",
    display: type2 ? "none" : ""
  },
  "& .MuiInputLabel-root": {
    fontSize: "1rem !important"
  },
  "@media only screen and  (min-width:280px){": {
    "& .MuiInputLabel-root": {
      fontSize: "1rem !important"
    }
  },
  "@media (min-width:768px) and (max-width:912px)": {
    "& .MuiInputLabel-root": {
      fontSize: "1rem !important"
    },
    width: "100%"
  },
  "@media (min-width:913px) ": {
    "& .MuiInputLabel-root": {
      fontSize: "1rem !important"
    },
    width: "100%"
  }
}));
export default function OTPDialog(props) {
  const vocabTerms = useContext(TermsContext)
  const router = useRouter()
  const { t } = useTranslation("common")
  const [cityName, setCityName] = useState("");
  const [cities, setCities] = useState([])
  const [inputList, setInputList] = useState({ code: null, error: { code: { value: false, message: t("fieldRequired", { fieldName: t("lblTheOneTimePassword") }) } } })


  const handleSend = (e) => {

    props.callBack(cities.filter(ele => ele.name == cityName)[0].name, false)
    if(cities.filter(ele=>ele.name==cityName).length>0){
      setCookiesValues("foodCity",JSON.stringify(cities.filter(ele=>ele.name==cityName)[0]))
    }
  }

  useEffect(() => {
    let StatusIds = vocabTerms.statusIds;

    getTermsByVocabCodes(["cities"]).then(res => {
      let lstOfCitites=JSON.parse(res).filter(ele =>ele.fieldConfig&&buidlObject(ele.fieldConfig).search&&buidlObject(ele.fieldConfig).search.includes("food")&& ele.language.code == router.locale&&StatusIds.includes(ele.statusId)&&ele.field1==getCookieValue("userCountry"))

      setCities(lstOfCitites)
    }).catch(err => console.log("err======>", err))
  }, [props.open])
  useEffect(() => {
    setInputList({ code: null, error: { code: { value: false, message: t("fieldRequired", { fieldName: t("lblTheOneTimePassword") }) } } })

  }, [props.reload])
  const handleChangeCities = (e) => {

 
    setCityName(e.target.value)
  }
  const handleClearCity = (e) => {
    setCityName('')
  }
  return (
    <>

      <Dialog
        open={props.open}
        maxWidth={"xs"}
        fullWidth
        onClose={props.Close}
        PaperProps={{
          sx: { overflow: "visible", position: "relative", direction: router.locale == "ar" ? "rtl" : "ltr", },

        }}
      >
        <CustomeDialogContent2 style={{ position: "relative", width: "100%", direction: router.locale == "ar" ? "rtl" : "ltr", }}>
          <CustomeFormControl fullWidth
            type2={cityName}
            variant="outlined"
          >
            <InputLabel id="City">{t("City")}</InputLabel>
            <Customeselect
              locale={router.locale}
              fullWidth
              labelId="City"
              id="City"
              label={t("City")}
              onChange={handleChangeCities}
              value={cityName}
              sx={{
                width: "100%",
                margin: "0 ",
                display: "flex !important",
                borderRadius: 0
              }}
              endAdornment={
                cityName ? <IconButton
                  sx={{
                    visibility: cityName ? "visible" : "hidden",
                    position: "relative",
                  }}
                  onClick={handleClearCity}
                >
                  <ClearIcon />
                </IconButton> : ""
              }
            >
              {cities.length > 0
                ? cities.map((city, index) => (
                  <MenuItem
                    style={{
                      direction: router.locale == "ar" ? "rtl" : "ltr",
                      alignItems: "right"
                    }}
                    value={city.name}
                    key={index}
                  >
                    {city.name}
                  </MenuItem>
                ))
                : ""}
            </Customeselect>
          </CustomeFormControl>

        </CustomeDialogContent2>
        <DialogActions
          justifyContent="center"
          style={{ justifyContent: "center", direction: router.locale == "ar" ? "rtl" : "ltr", }}
        >
          <Customebtn
            variant={"contained"}
            loading={props.loadingOtpBtn}
            onClick={handleSend}
            color="secondary"
          >
            {t("btnOk")}
          </Customebtn>
          <Customebtn
            variant={"contained"}
            loading={props.loadingOtpBtn}
            onClick={props.btnCancel}
          >
            {t("btnCancel")}
          </Customebtn>
        </DialogActions>
        {props.children}
      </Dialog>
    </>
  );
}
OTPDialog.propTypes = {
  confirm: PropTypes.func,
  reject: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  text: PropTypes.string
}