import React,{ useContext} from "react";
import style from "../../../styles/mystyle.module.css";
// import Container from '@mui/material/Container'
import { useTranslation } from "next-i18next";
import * as config from "../../../next.config.js";
import { constants } from "../../../constants";
import Image from 'next/image'
import ServiceContext from "../../../context/Services"
const SquareFooter = () => {
  const hakeemServicesList= useContext(ServiceContext)
  const { t } = useTranslation("common");
  return (
    <div className={style.containerwhite}>
              { hakeemServicesList && hakeemServicesList.hakeemServices && hakeemServicesList.hakeemServices.length > 0 && hakeemServicesList.hakeemServices.filter(i=>i.code=="support").length>0?
      <div className={style.allcard} style={{ position: "relative" }}>
        <div className={style.ColorSquareFooter}>
          <div className={style.SquareFooter}>
            <div className={style.cardsquare}>
              <div className={style.divtele}>
              <a href="tel:+967778876881"  target="_blank"  rel="noreferrer"  >
                <img
            
                  className={style.imgtele}
                  src={`${config.basePath}/assest/telephone.svg`}
                  width={100}
                  height={100}
                  alt={"hakeem"}
                /></a>
              </div>

              <div className={style.divmessg}>
              <a href="sms:+967778876881"  target="_blank"  rel="noreferrer"  >
                <img
                  className={style.imgsmeg}
                  src={`${config.basePath}/assest/messageus.svg`}
                  width={100}
                  height={100}
                  alt={"hakeem"}
                /></a>
              </div>
            </div>
            <div className={style.cardSupoort}>
              <div className={style.cards1}>
                <div className={style.headersupport}>
                  {t("TimeTechnicalSupportSupport")}
                  {t("TechnicalSupport")}{" "}
                </div>

                <p className={style.prasupport}>
                  {t("TechnicalSupportParagraph")}  {t("workingTimeOfHakeem")}
                  {/* {constants.MobileMed} */}
                </p>
              </div>

              <div   className={style.imgsupport}>
                <img
                  className={style.imgsupport}
                  src={`${config.basePath}/assest/technicalSupport.svg`}
                  width={100}
                  height={100}
                  alt={"hakeem"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>:""}
    </div>
  );
};
export default SquareFooter;
